import React, {
  useCallback,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";

import "./login.css";
import "./Particle";
import Modal from "./Modal";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useApiFunction from "./useApiFunction";
import { useTranslation } from "react-i18next";
import { locationContext } from "../Context/LocationContext";

function Register() {
  const { t } = useTranslation(["Login"]);
  const { accessToBuyProduct } = useContext(locationContext);
  const navigate = useNavigate();
  const { fetchFunc } = useApiFunction();
  const location = useLocation();
  const [verifyPh, setVerifyPh] = useState(false);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginInput, setLoginInput] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [delayDuration, setDelayDuration] = useState(1000);
  const [toggle, setToggle] = useState(false);
  let referral = "";
  referral = location?.state?.referral;

  // const [token, setToken] = useState("");
  const handleChange = (e) => {
    setLoginInput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });
  };
  const Toggle = () => setToggle((prv) => !prv);
  const fetchOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (loginInput.phone) {
      const body = {
        phone: loginInput.phone,
      };

      const res = await fetchFunc("send_otp_verify_phone", "POST", body);
      if (res.status) {
        toast.success(res.msg, { autoClose: 2000 });

        if (res.msg == "OTP send to the provided phone Number") {
          Toggle();
        } else if (
          res.msg == "Already a user registered with this phonenumber"
        ) {
          setTimeout(() => {
            navigate("/login");
          }, delayDuration);
        } else {
          setVerifyPh((prv) => !prv);
        }
      } else {
        toast.warning(res.msg, { autoClose: 2000 });
      }
    } else {
      toast.warning("Please fill your phone number!!!", {
        autoClose: 2000,
      });
    }
    setIsLoading(false);
  };
  async function submitLogin(e) {
    e.preventDefault();
    if (
      !loginInput.phone ||
      !loginInput.password ||
      !loginInput.email ||
      !loginInput.confirmpassword ||
      !loginInput.name
    ) {
      toast.warning("Please fill all the details", {
        autoClose: 2000,
      });
    } else if (loginInput.password !== loginInput.confirmpassword) {
      toast.warning("Password and Confirm Password must be same", {
        autoClose: 2000,
      });
    } else if (!verifyPh) {
      toast.warning("Please verify you Phone Number first before Registering", {
        autoClose: 2000,
      });
    } else {
      let body = {
        phone: loginInput.phone,
        password: loginInput.password,
        name: loginInput.name,
        email: loginInput.email,
      };

      const res = await fetchFunc(`register`, "POST", body);

      if (res.status) {
        toast.success("Registration Successful", { autoClose: 2000 });

        setTimeout(() => {
          referral
            ? navigate("/login", {
                state: { referral: referral, path: "product-details" },
              })
            : navigate("/login");
        }, delayDuration);
      } else {
        toast.warning(res.msg, { autoClose: 2000 });
      }
      setLoginInput({
        phone: "",
        password: "",
        name: "",
        email: "",
        confirmpassword: "",
      });
    }
  }

  const Adderess = useMemo(
    () => React.lazy(() => import("./PaymentAdderess")),
    []
  );

  useEffect(() => {
    if (!accessToBuyProduct) {
      navigate("/");
    }
  }, []);

  return (
    <div className="auth-page-wrapper pt-5">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div className="auth-page-content login-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <a className="d-inline-block auth-logo" href="/login">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                      className="white-logo"
                      alt="Logo"
                      width="60%"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4 card-bg full-withradius">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <div className="auth-heading waviy">
                      <span style={{ "--i": "1" }}>{t("login.welcom.w")}</span>
                      <span style={{ "--i": "2" }}>{t("login.welcom.e")}</span>
                      <span style={{ "--i": "3" }}>{t("login.welcom.l")}</span>
                      <span style={{ "--i": "4" }}>{t("login.welcom.c")}</span>
                      <span style={{ "--i": "5" }}>{t("login.welcom.o")}</span>
                      <span style={{ "--i": "6" }}>{t("login.welcom.m")}</span>
                      <span style={{ "--i": "7" }}>
                        {t("login.welcom.E")} &nbsp;
                      </span>
                      <span style={{ "--i": "8" }}>{t("login.welcom.b")}</span>
                      <span style={{ "--i": "9" }}>{t("login.welcom.a")}</span>
                      <span style={{ "--i": "10" }}>{t("login.welcom.c")}</span>
                      <span style={{ "--i": "11" }}>{t("login.welcom.k")}</span>
                      <span style={{ "--i": "12" }}>{t("login.welcom.!")}</span>
                    </div>
                    <p
                      className="mt-3"
                      style={{ color: "#fff", opacity: ".9" }}
                    >
                      {t("signup.description")}
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form>
                      <div className="mb-3">
                        <label
                          className="form-label auth-lable"
                          htmlFor="name-input"
                        >
                          {t("signup.name")}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type="string"
                            name="name"
                            className="form-control pe-5 name-input auth-input"
                            placeholder={t("signup.entername")}
                            id="name-input"
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="username"
                          className="form-label auth-lable"
                        >
                          {t("signup.phone")}
                        </label>
                        <div className="form-group d-flex">
                          {verifyPh ? (
                            <input
                              type="number"
                              className="form-control verify-mail"
                              placeholder={t("signup.enterphone")}
                              value={loginInput?.phone}
                              readOnly
                            />
                          ) : (
                            <>
                              <input
                                type="number"
                                id="phone"
                                name="phone"
                                onChange={(e) => handleChange(e)}
                                className="form-control verify-mail "
                                required
                                placeholder={t("signup.enterphone")}
                              />

                              <button
                                className="verify-btn"
                                onClick={(e) => fetchOtp(e)}
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading && (
                                  <i className="fa fa-refresh fa-spin" />
                                )}
                                {t("signup.verify")}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label for="email" className="form-label">
                          {t("signup.email")}
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={loginInput.email}
                          className="form-control"
                          onChange={(e) => handleChange(e)}
                          id="email"
                          placeholder={t("signup.enteremain")}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label auth-lable"
                          htmlFor="password-input"
                        >
                          {t("signup.password")}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type="password"
                            name="password"
                            className="form-control pe-5 password-input auth-input"
                            placeholder={t("signup.enterpassword")}
                            id="password-input"
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          className="form-label auth-lable"
                          htmlFor="password-input"
                        >
                          {t("signup.confirmpassword")}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type="password"
                            name="confirmpassword"
                            className="form-control pe-5 password-input auth-input"
                            placeholder={t("signup.enterconfirmpassword")}
                            id="password-input"
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input captchaCheckbox"
                          type="checkbox"
                          id="auth-remember-check"
                          // onClick={(e) => registerUser(e)}
                          // required
                          defaultValue="verify"
                        />
                        <label
                          className="form-check-label auth-lable captcha-lable"
                          htmlFor="auth-remember-check"
                        >
                          {t("signup.robot")}
                        </label>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100 auth-btn"
                          type="submit"
                          onClick={(e) => submitLogin(e)}
                        >
                          {t("signup.signin")}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0 auth-lable">
                          {t("signup.noaccount")}
                          <Link to="/login" className="fw-semibold auth-text">
                            {t("signup.login")}
                          </Link>
                        </p>
                        <p className="mb-0 auth-lable">
                          <Link
                            to="/"
                            className="fw-semibold auth-text"
                            style={{ cursor: "pointer" }}
                          >
                            {t("signup.home")}
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Toastify" />
      </div>
      {toggle && (
        <Modal show={toggle} close={Toggle}>
          <Adderess
            close={Toggle}
            setVerifyPh={setVerifyPh}
            fromRegister={true}
            verify={loginInput?.phone}
          />
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
}

export default Register;
