import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs4() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog4"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog4.title")}</h2>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog4.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog4.heading")}</h2>
                  <p>{t("blog4.para1")}</p>
                  <p>{t("blog4.para2")}</p>

                  <p>{t("blog4.para3")}</p>
                  <p>{t("blog4.para4")}</p>

                  <ol className="blog1-point">
                    <li>{t("blog4.point1.heading")}</li>

                    <p>{t("blog4.point1.para")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/exhaust-smoke.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("blog4.point2.heading")}</li>

                    <p>{t("blog4.point2.para")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/reduce-fule.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("blog4.point3.heading")}</li>

                    <p>{t("blog4.point3.para")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/vehicle-noise .png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("blog4.point4.heading")}</li>

                    <p>{t("blog4.point4.para")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/overheating.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("blog4.point5.heading")}</li>

                    <p>{t("blog4.point5.para")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/gera-box.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                  </ol>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs4;
