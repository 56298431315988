import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs2() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs2"]);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <>
        <Header />
        <div className="page-title-area main-bg">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="title-item">
                  <h2>{t("blogs3.title")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-details-area ptb-100">
          <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
                <div className="blog-details-item">
                  <div className="blog-details-name">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "blogs3.img"
                      )}.png`}
                      width="100%"
                    />

                    <h2>{t("blogs3.heading")}</h2>
                    <p>{t("blogs3.line1")}</p>
                    <p>{t("blogs3.line2")}</p>
                    <p>{t("blogs3.line3")}</p>

                    <ol className="blog1-point">
                      <li>{t("blogs3.point1")}</li>

                      <p>{t("blogs3.point1.1")}</p>
                      <p>{t("blogs3.point1.2")}</p>
                      <p>{t("blogs3.point1.3")}</p>
                      <p>{t("blogs3.point1.4")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/smoke-prevent.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>

                      <li>{t("blogs3.point2")}</li>

                      <p>{t("blogs3.point2.1")}</p>
                      <p>{t("blogs3.point2.2")}</p>
                      <p>{t("blogs3.point2.3")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="https://www.busrepair.ae/wp-content/uploads/2019/11/Oil-change-Sharjah.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>
                      <li>{t("blogs3.point3")}</li>

                      <p>{t("blogs3.point3.1")}</p>

                      <p>{t("blogs3.point3.2")}</p>
                      <p>{t("blogs3.point3.3")}</p>
                      <p>{t("blogs3.point3.4")}</p>
                      <p>{t("blogs3.point3.5")}</p>
                      <p>{t("blogs3.point3.6")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/cooleant-.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>
                      <li>{t("blogs3.point4")}</li>

                      <p>{t("blogs3.point4.1")}</p>
                      <p>{t("blogs3.point4.2")}</p>
                      <p>{t("blogs3.point4.3")}</p>
                      <p>{t("blogs3.point4.4")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/change-oil-remove.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>

                      <li>{t("blogs3.point5")}</li>

                      <p>{t("blogs3.point5.1")}</p>
                      <p>{t("blogs3.point5.2")}</p>
                      <h3 style={{ color: "#F89F31", fontWeight: "600" }}>
                        {t("blogs3.point6")}
                      </h3>

                      <p>{t("blogs3.point6.1")}</p>
                    </ol>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default Blogs2;
