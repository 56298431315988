import React, { useCallback, useState } from "react";

import "./login.css";
import "./Particle";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import useApiFunction from "./useApiFunction";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function ForgetPassword() {
  const { t } = useTranslation(["Login"]);
  // const [toggle, setToggle] = useState(false);
  // const Toggle = () => setToggle((prv) => !prv);
  // const navigate = useNavigate();
  // const location = useLocation();

  // let path = location?.state?.path;
  // let referral = location?.state?.referral;

  // const { fetchFunc } = useApiFunction();
  // // const { executeRecaptcha } = useGoogleReCaptcha();
  // const [loginInput, setLoginInput] = useState({});
  // // const [token, setToken] = useState("");
  // const handleChange = (e) => {
  //   setLoginInput({
  //     ...loginInput,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // async function submitLogin(e) {
  //   e.preventDefault();
  //   if (!loginInput.phone || !loginInput.password) {
  //     toast.warning("Please fill all the details", {
  //       autoClose: 2000,
  //     });
  //   } else {
  //     let body = {
  //       phone: loginInput.phone,
  //       password: loginInput.password,
  //       // captcha_token: token,
  //     };

  //     const res = await fetchFunc(`login`, "POST", body);

  //     if (res.status) {
  //       toast.success("Welcome to tirrent global", { autoClose: 2000 });
  //       sessionStorage.setItem("token", res.msg.token);
  //       path == "product-details"
  //         ? navigate(`/product-details?refid=${referral}`)
  //         : navigate("/");
  //     } else {
  //       toast.warning(res.msg, { autoClose: 2000 });
  //     }
  //     setLoginInput({
  //       phone: "",
  //       password: "",
  //     });
  //   }
  // }
  // const redirect = () => {
  //   if (referral) {
  //     navigate("/register", { state: { referral: referral } });
  //   } else {
  //     navigate("/register");
  //   }
  // };
  // const getToken = () => {
  //   const token = sessionStorage.getItem("token");
  //   if (token) {
  //     navigate("/");
  //   }
  // };
  // useEffect(() => {
  //   getToken();
  // }, []);

  return (
    <>
      <div className="auth-page-wrapper pt-5">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="auth-page-content login-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <a className="d-inline-block auth-logo" href="/login">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                        className="white-logo"
                        alt="Logo"
                        width="60%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4 card-bg full-withradius">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <div className="auth-heading waviy">
                        <span style={{ "--i": "1" }}>
                          {t("forget.welcom.w")}
                        </span>
                        <span style={{ "--i": "2" }}>
                          {t("forget.welcom.e")}
                        </span>
                        <span style={{ "--i": "3" }}>
                          {t("forget.welcom.l")}
                        </span>
                        <span style={{ "--i": "4" }}>
                          {t("forget.welcom.c")}
                        </span>
                        <span style={{ "--i": "5" }}>
                          {t("forget.welcom.o")}
                        </span>
                        <span style={{ "--i": "6" }}>
                          {t("forget.welcom.m")}
                        </span>
                        <span style={{ "--i": "7" }}>
                          {t("forget.welcom.E")} &nbsp;
                        </span>
                        <span style={{ "--i": "8" }}>
                          {t("forget.welcom.b")}
                        </span>
                        <span style={{ "--i": "9" }}>
                          {t("forget.welcom.a")}
                        </span>
                        <span style={{ "--i": "10" }}>
                          {t("forget.welcom.c")}
                        </span>
                        <span style={{ "--i": "11" }}>
                          {t("forget.welcom.k")}
                        </span>
                        <span style={{ "--i": "12" }}>
                          {t("forget.welcom.!")}
                        </span>
                      </div>
                      <p
                        className="mt-3"
                        style={{ color: "#fff", opacity: ".9" }}
                      >
                        {t("forget.description")}
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <form>
                        <div className="mb-3">
                          <label
                            htmlFor="username"
                            className="form-label auth-lable"
                          >
                            {t("forget.enterphonenumber")}
                          </label>
                          <input
                            type="number"
                            name="phone"
                            className="form-control auth-input"
                            id="mobile"
                            placeholder={t("forget.usernumber")}
                            // onChange={(e) => handleChange(e)}
                            // value={loginInput?.phone}
                            required
                          />
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100 auth-btn"
                            type="submit"
                            // onClick={(e) => modalOpen(e)}
                          >
                            {t("forget.password")}
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0 auth-lable">
                            {t("forget.noaccount")}
                            <span
                              to="/"
                              onClick={() => {
                                // redirect();
                              }}
                              className="fw-semibold auth-text"
                              style={{ cursor: "pointer" }}
                            >
                              {t("forget.signup")}
                            </span>
                          </p>
                          <p className="mb-0 auth-lable">
                            <Link
                              to="/"
                              className="fw-semibold auth-text"
                              style={{ cursor: "pointer" }}
                            >
                              {t("forget.home")}
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Toastify" />
        </div>
        <ToastContainer />
      </div>
      {/* {toggle && (
        <Modal show={toggle} close={Toggle}>
          <Adderess
            close={Toggle}
            setTabIndex={setTabIndex}
            verify={verify}
            setVerifyPh={setVerifyPh}
          />
        </Modal>
      )} */}
    </>
  );
}

export default ForgetPassword;
