import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs7() {
  const { t } = useTranslation(["Blogs10"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog10.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog10.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog10.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog10.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog10.img"
                    )}`}
                    width="100%"
                  />

                  <h2>{t("blog10.heading")}</h2>
                  <p>{t("blog10.para")}</p>
                  <p>{t("blog10.para1")}</p>
                  {/* <h4 className="blog6-subheading">{t("blog10.head")}</h4> */}
                  <ul className="blog6-point">
                    <li> {t("blog10.point2.1")}</li>
                    <p>{t("blog10.para2.1")}</p>

                    <li> {t("blog10.point2.2")}</li>
                    <p>{t("blog10.para2.2")}</p>

                    <li> {t("blog10.point2.3")}</li>
                    <p>{t("blog10.para2.3")}</p>
                  </ul>
                  <p>{t("blog10.para3")}</p>
                  <h4 className="blog6-subheading">{t("blog10.point4")}</h4>
                  <p>{t("blog10.para4")}</p>
                  <h4 className="blog6-subheading">{t("blog10.point5")}</h4>
                  <p>{t("blog10.para5")}</p>

                  <ul className="blog6-point">
                    <li> {t("blog10.point5.1")}</li>
                    <p>{t("blog10.para5.1")}</p>

                    <li> {t("blog10.point5.2")}</li>
                    <p>{t("blog10.para5.2")}</p>

                    <li> {t("blog10.point5.3")}</li>
                    <p>{t("blog10.para5.3")}</p>
                  </ul>

                  <h4 className="blog6-subheading">{t("blog10.point6")}</h4>
                  <ul className="blog6-point">
                    <li> {t("blog10.point6.1")}</li>
                    <p>{t("blog10.para6.1")}</p>

                    <li> {t("blog10.point6.2")}</li>
                    <p>{t("blog10.para6.2")}</p>

                    <li> {t("blog10.point6.3")}</li>
                    <p>{t("blog10.para6.3")}</p>

                    <li> {t("blog10.point6.4")}</li>
                    <p>{t("blog10.para6.4")}</p>
                    <li> {t("blog10.point6.5")}</li>
                    <p>{t("blog10.para6.5")}</p>
                  </ul>
                  <h4 className="blog6-subheading">{t("blog10.point7")}</h4>
                  <p>{t("blog10.para7")}</p>
                  <ul className="blog6-point">
                    <li> {t("blog10.point7.1")}</li>
                    <p>{t("blog10.para7.1")}</p>

                    <li> {t("blog10.point7.2")}</li>
                    <p>{t("blog10.para7.2")}</p>

                    <li> {t("blog10.point7.3")}</li>
                    <p>{t("blog10.para7.3")}</p>

                    <li> {t("blog10.point7.4")}</li>
                    <p>{t("blog10.para7.4")}</p>
                    <li> {t("blog10.point7.5")}</li>
                    <p>{t("blog10.para7.5")}</p>
                  </ul>
                  <h4 className="blog6-subheading">{t("blog10.point8")}</h4>
                  <ul className="blog6-point">
                    <p>{t("blog10.para8.1")}</p>

                    <p>{t("blog10.para8.2")}</p>
                  </ul>

                  <h4 className="blog6-subheading">{t("blog10.point9")}</h4>
                  <p>{t("blog10.para9")}</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs7;
