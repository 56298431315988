import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

function Support() {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const change = (e) => {
    setData({ [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Header />
      <div className="about-content">
        <div className="adderess-card w-100">
          <div className="adderess-section">
            <div className="ticket-body">
              <h4 className="ticket-title">Support</h4>
              <div
                className="row contact-area"
                style={{ backgroundImage: "none" }}
              >
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>Email</span>
                  <div className="form-group d-flex">
                    <input
                      type="email"
                      id="email"
                      onChange={() => change}
                      className="form-control verify-mail"
                      required
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>Mobile Number</span>
                  <div className="form-group d-flex">
                    <input
                      type="number"
                      id="phone"
                      onChange={() => change}
                      className="form-control verify-mail"
                      required
                      placeholder="Mobile number"
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>Query</span>
                  <div className="form-group">
                    <textarea
                      type="textarea"
                      id="address"
                      rows="4"
                      cols="50"
                      onChange={() => change}
                      className="form-control"
                      required
                      placeholder="Type here..."
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center ">
                <button
                  className="button-animation mb-3 mt-3"
                  style={{ border: "none" }}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <i className="fa fa-refresh fa-spin" />}
                  Genrate Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Support;
