import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
// import image from "../../../../public"
function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const { t } = useTranslation(["Cart"]);
  const navigate = useNavigate();
  let counts;
  function close(e) {
    e.preventDefault();
    const { id } = e.target;
    const filter = cartItems.filter((data, i) => i != id);
    setCartItems([...filter]);
    localStorage.setItem("cart", JSON.stringify([...filter]));
  }
  if (cartItems) {
    counts = cartItems.reduce(
      (preValue, currentValue) => preValue + Number(currentValue.Quantity),
      0
    );
  }
  useEffect(() => {
    const data = localStorage.getItem("cart");
    if (data) {
      setCartItems(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <Header />

      <div className="container">
        <div
          className="row"
          style={{ marginTop: "0px", padding: "150px 0px 50px 0px" }}
        >
          <div
            className={
              cartItems.length > 0 ? `col-md-8 mb-2` : `col-md-12 mb-2`
            }
          >
            <div className="adderess-card w-100">
              <div className="adderess-section">
                <div className="ticket-body">
                  <h4 className="ticket-title">{t("cart.title")}</h4>
                  {cartItems.length > 0 ? (
                    cartItems.map((data, i) => {
                      return (
                        <div className="row order-detail mb-4" key={i}>
                          <div className="col-md-5">
                            <img
                              src={
                                data.Image
                                  ? data.Image
                                  : "/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png"
                              }
                              width="100%"
                              alt="img"
                            />
                          </div>
                          <div className="col-md-7 details-table">
                            <table className="table table-section details-table">
                              <tr>
                                <th>
                                  {t("cart.name")}
                                  :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </th>
                                <td>{data.ProductName}</td>
                              </tr>
                              <tr>
                                <th>{t("cart.size")}: </th>
                                <td>{data.Size}{t("cart.liter")}</td>
                              </tr>
                              <tr>
                                <th>{t("cart.qut")}:</th>
                                <td>{data.Quantity}</td>
                              </tr>
                              <tr>
                                <th>{t("cart.dis")}:</th>
                                <td>{t("cart.rs")} { data.Quantity*t("cart.discount")}</td>
                              </tr>
                              <tr>
                                <th>{t("cart.amt")}:</th>
                                <td>{t("cart.rs")} { data.Quantity*t("cart.amount")}</td>
                              </tr>
                            </table>
                            <div className="d-flex justify-content-center">
                              <button
                                className="ml-3 button-animation"
                                style={{
                                  border: "none",
                                }}
                                id={i}
                                onClick={(e) => close(e)}
                              >
                                {t("cart.cancle")}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="order-detail mb-4 d-flex justify-content-center">
                      <div className="">
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/empty-cart.png"
                          width="100%"
                          alt="img"
                        />
                        <h5 className="text-center pb-3">
                          <span
                            style={{
                              backgroundImage:
                                "linear-gradient(to right, #ee993a 0%, #bf5c21 50%)",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            {t("cart.srymsg")}
                          </span>
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {cartItems.length > 0 ? (
            <div className="col-md-4 mb-2">
              <div className="adderess-card w-100">
                <div className="adderess-section">
                  <div className="ticket-body">
                    <h4 className="ticket-title">{t("price.title")}</h4>
                    <div className="mb-4">
                      <div className="details-table">
                        <table className="table table-section">
                          <tr>
                            <th>
                              {t("price.mrp")}
                              :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </th>
                            <td style={{ paddingLeft: "3em" }}>
                              {t("price.rs")} {counts > 0 ? counts * t("price.amount") : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>{t("price.dismrp")}: </th>
                            <td style={{ paddingLeft: "3em" }}>
                              {t("price.rs")} {counts > 0 ? counts * t("price.discount") : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>{t("price.amt")}:</th>
                            <td style={{ paddingLeft: "3em" }}>
                              {t("price.rs")} {counts > 0 ? counts * t("price.finalprice") : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="button-animation"
                        type="submit"
                        style={{ border: "none" }}
                        onClick={() =>
                          navigate("/payment-method", {
                            state: { quentity: counts },
                          })
                        }
                      >
                        {t("price.proceed")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Cart;
