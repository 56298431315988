import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import useApiFunction from "../Shared/useApiFunction";
import { toast, ToastContainer } from "react-toastify";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

function History() {
  const { t } = useTranslation(["History"]);
  const [items, setItems] = useState([]);
  const { fetchFunc } = useApiFunction();
  const color = useRef(null);

  const navigate = useNavigate();
  const fetchPreviousOrder = async () => {
    const res = await fetchFunc("get_orders_history", "GET", {});
    if (res.status) {
      setItems(res.msg);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    fetchPreviousOrder();
  }, []);

  return (
    <>
      <Header />

      <div className="container">
        <div
          className="row"
          style={{ marginTop: "0px", padding: "150px 0px 50px 0px" }}
        >
          <div className="col-md-12 mb-2">
            <div className="adderess-card w-100">
              <div className="adderess-section">
                <div className="ticket-body">
                  <h4 className="ticket-title">{t("history.title")}</h4>
                  {items.length > 0 ? (
                    items.map((data, i) => {
                      if (data.status == "failed") {
                        color.current.style.color = "red";
                      }
                      if (data.status == "success") {
                        color.current.style.color = "green";
                      }
                      return (
                        <>
                          <div className="row order-detail mb-4" key={i}>
                            <div
                              className="col-md-4"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src={
                                  data.Image
                                    ? data.Image
                                    : "/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png"
                                }
                                width="100%"
                                alt="img"
                                className="order-history"
                              />
                            </div>
                            <div className="col-md-7">
                              <table className="table table-section">
                                <tr>
                                  <th>
                                    {t("history.status")}
                                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <td ref={color}>
                                    {data.status.toUpperCase()}
                                  </td>
                                </tr>
                                <tr>
                                  <th>{t("history.name")}: </th>
                                  <td>{data.name}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.phone")}:</th>
                                  <td>{data.phone}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.address")}:</th>
                                  <td>{data.address}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.city")}:</th>
                                  <td>{data.city}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.id")}:</th>
                                  <td>{data.txnid}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.qut")}:</th>
                                  <td>{Math.floor(data.amount / 2000)}</td>
                                </tr>
                                <tr>
                                  <th>{t("history.amt")}:</th>
                                  <td>{data.amount}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      <Footer />
    </>
  );
}

export default History;
