import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import useApiFunction from "./useApiFunction";
import { locationContext } from "../Context/LocationContext";

function Header() {
  const { i18n, t } = useTranslation(["Navbar"]);
  const [openDrop, setOpenDrop] = useState(false);
  const [language, setLanguage] = useState("");
  const { accessToBuyProduct } = useContext(locationContext);
  const [open, setOpen] = useState(false);
  const lang = useRef();
  const pathLocation = useLocation();
  const [openList, setOpenList] = useState("");
  const { fetchFunc } = useApiFunction();

  const [userToken, setUserToken] = useState("");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [toggle, setToggle] = useState(false);
  let counts = 0;
  let date = localStorage.getItem("cart");
  const modalRef = useRef(null);
  const ref = useRef(null);
  const navigate = useNavigate();
  if (data) {
    counts = data.length;
  }
  const logout = (e) => {
    sessionStorage.clear();
    setUserToken("");
  };
  const openMenuList = () => {
    setOpenList(!openList);
  };
  const getToken = () => {
    const userId = sessionStorage.getItem("token");
    setUserToken(userId);
    if (userId) {
      getUser();
    }
  };

  function handelCart() {
    navigate("/cart");
  }
  const getUser = async () => {
    const res = await fetchFunc("get_user", "GET", {});
    if (res.status) {
      setUserData(res.msg);
    } else {
      logout();
    }
  };

  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (ref.current && !ref.current.contains(e.target)) {
  //       setOpenList(false);
  //     }
  //     if (modalRef.current && !modalRef.current.contains(e.target)) {
  //       setToggle(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside, true);
  //   // document.addEventListener("click", closeModal, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //     // document.removeEventListener("click", closeModal, true);
  //   };
  // }, []);

  useEffect(() => {
    setData(JSON.parse(date));
    getToken();
  }, [date, userToken]);

  //language functionality starts

  const getLanguage = (e) => {
    const language = localStorage.getItem("i18nextLng") || "en";
    setLanguage(language);
  };

  const changeLanguage = (e) => {
    setOpenDrop((prv) => !prv);
    i18n.changeLanguage(e.currentTarget.id);
    setLanguage(e.currentTarget.id);
  };

  useEffect(() => {
    getLanguage();
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  //language functionality ends

  return (
    <>
      <div className="navbar-area sticky-top">
        {/* mobile screen */}
        <div className="mobile-nav mean-container">
          <div className="mean-bar">
            <a
              className={`${t(`class.meanmenu-reveal`)}`}
              onClick={openMenuList}
            >
              <span>
                <span>
                  <span></span>
                </span>
              </span>
            </a>
            <Link to="/" className={t("class.logo")}>
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                width="36%"
                alt="Logo"
              />
            </Link>

            <nav className="mean-nav">
              <ul
                className={
                  openList ? "navbar-nav show-items" : "navbar-nav remove-items"
                }
                ref={ref}
              >
                <li className="nav-item">
                  <Link
                    to="/"
                    className={
                      pathLocation.pathname === "/"
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }
                  >
                    {t("navlinks.home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/about"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/about"
                  >
                    {t("navlinks.about")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/research-and-technology"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/research-and-technology"
                  >
                    {t("navlinks.research")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/products"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="#"
                    
                  >
                    {t("navlinks.productDetails")}
                    <i className={!open?`fa-solid fa-angle-down`:`fa-solid fa-angle-up`} style={{display:"block",float:"right"}} onClick={()=>setOpen(!open)}></i>
                    
                    {open && (
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                         
                          <Link to="/tirrent-booster" className="nav-link">
                          <i className="fa-solid fa-circle"></i>  Tirrent Booster
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/fuel-conditioner" className="nav-link">
                          <i className="fa-solid fa-circle"></i> Tirrent Fuel conditioner
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/tg-10" className="nav-link">
                          <i className="fa-solid fa-circle"></i>  TG-10
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/ep-2-lithium-complex-grease"
                            className="nav-link"
                          >
                          <i className="fa-solid fa-circle"></i>  EP-2 Lithium Complex Grease
                          </Link>
                        </li>
                      </ul>
                    )}
                  </Link>
                  
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/gallery"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/gallery"
                  >
                    {t("navlinks.gallery")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/contact"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/contact"
                  >
                    {t("navlinks.contact")}
                  </Link>
                </li>
              </ul>
              {/* small-scree-login */}
              {accessToBuyProduct && (
                <div
                  className={`${t(
                    "class.side-nav"
                  )} d-flex gap-3 align-item-center`}
                >
                  {userToken ? (
                    <div className="">
                      <div
                        className="dropdown"
                        onClick={() => {
                          setToggle((prev) => !prev);
                        }}
                        ref={modalRef}
                      >
                        <a
                          className="rounded-circle p-0"
                          href="#"
                          role="button"
                          id="dropdownUser"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="avatar avatar-md avatar-indicators avatar-online">
                            <img
                              alt="avatar"
                              src="SiteTemplate/TirrentGlobal/assets/img/logo----.png"
                              className="rounded-circle"
                            />
                          </div>
                        </a>
                      </div>
                      {toggle && (
                        <div
                          className={`${t("class.dropdown-menu")}`}
                          aria-labelledby="dropdownUser"
                        >
                          <div className="dropdown-item">
                            <div className="">
                              <div className="ml-3 lh-1 user-email">
                                <h5 className="mb-0">
                                  {userData.name ? userData.name : "-"}
                                </h5>
                                <p className="mb-0">
                                  {userData.email ? userData.email : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="dropdown-divider"></div>
                          <ul className="list-unstyled">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/history-previous"
                              >
                                <span className="mr-1">
                                  <i
                                    className="fa fa-history"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                &nbsp;{t("userLinks.order")}
                              </Link>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                // onClick={() => directProfile()}
                              >
                                <span className="mr-2">
                                  <i className="fa fa-user"></i>
                                </span>
                                &nbsp;{t("userLinks.profile")}
                              </a>
                            </li>
                            <li>
                              <div
                                className="dropdown-item"
                                onClick={(e) => {
                                  logout(e);
                                }}
                              >
                                <span className="mr-2">
                                  <i className="fa fa-power-off"></i>
                                </span>
                                &nbsp; {t("userLinks.signout")}
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ) : (
                    // <div className="side-nav d-flex align-items-center">
                    <div className="buy-btn-sm">
                      <Link to="/login">{t("button.login")}</Link>{" "}
                    </div>
                  )}
                </div>
              )}
            </nav>
          </div>
        </div>

        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand" style={{ width: "10%" }}>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                  className="white-logo"
                  alt="Logo"
                />
              </Link>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={
                        pathLocation.pathname === "/"
                          ? "nav-link dropdown-toggle active"
                          : "nav-link dropdown-toggle"
                      }
                    >
                      {t("navlinks.home")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/about"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/about"
                    >
                      {t("navlinks.about")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/research-and-technology"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/research-and-technology"
                    >
                      {t("navlinks.research")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to=""> {t("navlinks.productDetails")}</Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          className={
                            pathLocation.pathname === "/tirrent-booster"
                              ? "nav-link  active"
                              : "nav-link "
                          }
                          to="/tirrent-booster"
                        >
                          Tirrent Booster
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className={
                            pathLocation.pathname === "/fuel-conditioner"
                              ? "nav-link  active"
                              : "nav-link "
                          }
                          to="/fuel-conditioner"
                        >
                          Tirrent Fuel conditioner
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            pathLocation.pathname === "/tg-10"
                              ? "nav-link  active"
                              : "nav-link "
                          }
                          to="/tg-10"
                        >
                          TG-10
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            pathLocation.pathname ===
                            "/ep-2-lithium-complex-grease"
                              ? "nav-link  active"
                              : "nav-link "
                          }
                          to="/ep-2-lithium-complex-grease"
                        >
                          EP-2 Lithium Complex Grease
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/"
                    >
                      {t("navlinks.suppliers")}
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/gallery"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/gallery"
                    >
                      {t("navlinks.gallery")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/contact"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/contact"
                    >
                      {t("navlinks.contact")}
                    </Link>
                  </li>
                </ul>

                {accessToBuyProduct && (
                  <>
                    <div className="" style={{ marginRight: "10px" }}>
                      <i
                        className="fa-solid fa-cart-shopping cart"
                        value={counts > 0 ? `${counts}` : "0"}
                        onClick={() => handelCart()}
                      ></i>
                    </div>
                    {userToken ? (
                      <ul className="list-unstyled">
                        <li
                          className="dropdown ml-2"
                          onClick={() => setToggle((prev) => !prev)}
                          ref={modalRef}
                        >
                          <a
                            className="rounded-circle "
                            href="#"
                            role="button"
                            id="dropdownUser"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div
                              className="avatar avatar-lg avatar-indicators avatar-online"
                              style={{ marginLeft: "10px" }}
                            >
                              <img
                                alt="avatar"
                                src="SiteTemplate/TirrentGlobal/assets/img/logo----.png"
                                className="rounded-circle"
                              />
                            </div>
                          </a>

                          {toggle && (
                            <div
                              className={`${t(`class.dropdown-menu`)}`}
                              aria-labelledby="dropdownUser"
                            >
                              <div className="dropdown-item">
                                <div className="">
                                  <div className="ml-3 lh-1 user-email">
                                    <h5 className="mb-0">
                                      {userData.name ? userData.name : "-"}
                                    </h5>
                                    <p className="mb-0">
                                      {userData.email ? userData.email : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-divider"></div>
                              <div className="">
                                <ul className="list-unstyled">
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="/history-previous"
                                    >
                                      <span className="mr-1">
                                        <i
                                          className="fa fa-history"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      &nbsp; {t("userLinks.order")}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-divider"></div>

                              <ul className="list-unstyled">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    // onClick={() => directProfile()}
                                  >
                                    <span className="mr-1">
                                      <i className="fa fa-user"></i>
                                    </span>
                                    &nbsp; {t("userLinks.profile")}
                                  </a>
                                </li>
                              </ul>

                              <div className="dropdown-divider"></div>
                              <ul className="list-unstyled">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={logout}
                                  >
                                    <span className="mr-2">
                                      <i className="fa fa-power-off"></i>
                                    </span>
                                    &nbsp; {t("userLinks.signout")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </li>
                      </ul>
                    ) : (
                      <div
                        className={`side-nav ${t(
                          "class.side-nav"
                        )} d-flex align-items-center mr-2`}
                      >
                        <div className="buy-btn lg">
                          <Link to="/login" className="p-0 ">
                            {t("button.login")}
                          </Link>{" "}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div
        id="toTop"
        className={`back-to-top-btn` + (language == "ar" ? " left" : " right")}
        style={{ display: "block" }}
      >
        <div className="dropdown">
          <div
            className="d-flex drop"
            onClick={() => {
              setOpenDrop((prev) => !prev);
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "0 6px 0 0" }}>
              {language === "ar" ? (
                <img
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/ar_flag.jpg"
                  alt="flag"
                  width="93%"
                />
              ) : (
                <img
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/usa_flag.png"
                  alt="flag"
                  width="93%"
                />
              )}
            </div>
          </div>
          {openDrop && (
            <div className="user-email drop m-1 hover ">
              <div
                id="en"
                onClick={(e) => {
                  changeLanguage(e);
                }}
                style={{ padding: "6px 15px" }}
              >
                <img
                  alt="flag-img"
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/usa_flag.png"
                  style={{ margin: "0 6px 0 0" }}
                />
              </div>

              <div
                id="ar"
                onClick={(e) => {
                  changeLanguage(e);
                }}
                style={{ padding: "6px 15px" }}
              >
                <img
                  alt="flag-img"
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/ar_flag.jpg"
                  style={{ margin: "0 6px 0 0" }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Header;
