import React, { useEffect } from "react";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Contact() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Contact"]);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  const responsive = {
    0: { items: 1 },
    700: { items: 2 },
    1000: { items: 3 },
  };
  const items = [, , ,];
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2> {t("contact.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-area ptb-100">
        <div className="container">
          <div className="row contact-bg">
            <div className="col-lg-7">
              <div className="contact-item contact-left">
                <img
                  src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                    "1.leftImg"
                  )}.png`}
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="connect">
                <div style={{ marginBottom: "30px" }}>
                  <h1>{t("1.getintouch")}</h1>
                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>
                  <span>
                    <i
                      className="fa fa-envelope"
                      style={{ color: "#f4a340" }}
                    ></i>
                  </span>
                  <h6>support@tirrentglobal.com</h6>
                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>
                  <span>
                    <i className="fa fa-globe" style={{ color: "#f4a340" }}></i>
                  </span>
                  <h6>www.tirrentglobal.com</h6>
                </div>
                <div className="text-center" style={{ marginBottom: "22px" }}>
                  <span>
                    <i
                      className="fa fa-house-chimney"
                      style={{ color: "#f4a340" }}
                    ></i>
                  </span>
                  <h6 className="mb-0">{t("1.usaaddress")}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offer-slider owl-theme maps">
          <div className="row">
            <div className="col-md-4">
              <div className="offer-item">
                <div className="offer-top">
                  <div className="map-area">
                    <iframe
                      id="map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.8098006869864!2d-75.55273758423664!3d39.74392307944897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd41d1a737f9%3A0x3436f2a9a66570da!2sN%20Orange%20St%2C%20Wilmington%2C%20DE%2019801%2C%20USA!5e0!3m2!1sen!2sin!4v1656998609709!5m2!1sen!2sin"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe>
                  </div>
                  {/* <i className="flaticon-3d-print"></i> */}
                </div>
                <div className="offer-bottom" style={{ marginTop: "30px" }}>
                  <h3>
                    <a> {t("1.usa")}</a>
                  </h3>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <i className="fa fa-home"></i> Tirrent Global LLC
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot"></i>
                      {t("1.usaaddress")}
                    </li>{" "}
                    <li>
                      <i className="fa-solid fa-envelope"></i>{" "}
                      support@tirrent.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="offer-item">
                <div className="offer-top">
                  <div className="map-area">
                    <iframe
                      id="map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.027374481878!2d72.83394931537877!3d19.150278987045333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6477b5624d9%3A0x341eb35d56c5c9bf!2sAnand%20Nagar!5e0!3m2!1sen!2sin!4v1656998544021!5m2!1sen!2sin"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe>
                  </div>
                </div>
                <div className="offer-bottom" style={{ marginTop: "30px" }}>
                  <h3>
                    <a> {t("1.india")}</a>
                  </h3>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <i className="fa fa-home"></i>Tirrent Global Pvt. Ltd.
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {t("1.indiaAddress")}
                    </li>{" "}
                    <li>
                      <i className="fa-solid fa-envelope"></i>{" "}
                      support@tirrentglobal.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="offer-item">
                <div className="offer-top">
                  <div className="map-area">
                    <iframe
                      id="map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59791272.948469065!2d62.57506029141487!3d23.84100284788009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1594975629033!5m2!1sen!2sbd"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe>
                  </div>
                </div>
                <div className="offer-bottom" style={{ marginTop: "30px" }}>
                  <h3>
                    <a>{t("1.uae")}</a>
                  </h3>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <i className="fa fa-home"></i>
                      Tirrent Global General Trading Co. L.L.C
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot"></i>
                      {t("1.uaeAddress")}
                    </li>{" "}
                    <li>
                      <i className="fa-solid fa-envelope"></i>
                      support@tirrentglobal.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="row d-flex justify-content-center">
                <div
                  className="col-md-10"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="contact-item contact-right pt-100">
                    <h3 style={{ color: "#000" }}>{t("1.getintouch")}</h3>
                    <form id="contactForm">
                      <div className="row">
                        <div className="col-sm-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              required
                              data-error="Please enter your name"
                              placeholder={t("1.name")}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              required
                              data-error="Please enter your email"
                              placeholder={t("1.email")}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="phone_number"
                              id="phone_number"
                              required
                              data-error="Please enter your number"
                              className="form-control"
                              placeholder={t("1.phone")}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="msg_subject"
                              id="msg_subject"
                              className="form-control"
                              required
                              data-error="Please enter your subject"
                              placeholder={t("1.subject")}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              id="message"
                              cols="30"
                              rows="8"
                              required
                              data-error="Write your message"
                              placeholder={t("1.msg")}
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                          <button type="submit" className="contact-btn btn">
                            {t("1.bntmsg")}
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="map-area">
        <iframe
          id="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59791272.948469065!2d62.57506029141487!3d23.84100284788009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1594975629033!5m2!1sen!2sbd"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div> */}
      <Footer />
    </>
  );
}

export default Contact;
