import React, { useEffect } from "react";

import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useLocation } from "react-router-dom";

function TermsAndCondtion() {
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area terms-condition">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Terms And Condtions</h2>
            <div className="container">
              <div className="title-item">
                <h2>Terms And Condtions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>Terms And Condtions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <h2>Terms and Conditions of Direct Seller Agreement</h2>
              <p>
                These Terms and Conditions are to be read together with the
                Company Policies & Procedures Rulebook, Direct Seller
                Registration programme), Speaker guidelines, Tirrent Global
                Product Information and collectively they constitute a binding
                contract between Tirrent Global LLC (Company) and Direct Seller
                signing this terms and conditions.{" "}
              </p>
              <ol>
                <li className="term-conditions-order-list">Definitions</li>
                <ol type="A">
                  <li>Direct Seller:</li>
                  <p>
                    shall mean a person appointed by the Company on a
                    protocol–to-protocol basis through this Direct Seller
                    Contract to undertake sale, distribution and marketing of
                    Tirrent Global products and services and to register
                    Preferred Customers. A Tirrent Global Direct Seller may
                    introduce or sponsor another direct sellers and support them
                    to build their direct selling business of Tirrent Global
                    products & services.{" "}
                  </p>
                  <li>Direct Seller Contract:</li>
                  <p>shall mean and include the following:</p>
                  <ol type="i">
                    <li>The Direct Seller Application Form;</li>
                    <li>
                      These Terms and Conditions forming part of Direct Seller
                      Application;
                    </li>
                    <li>The Tirrent Global Compensation Plan;</li>
                    <li>
                      The Policies and Procedures Rulebook; as amended from time
                      to time. The Company may notify any such amendment on its
                      website, www.TirrentGlobal.com{" "}
                    </li>
                  </ol>
                  <li>Territory:</li>
                  <p>Shall mean the Republic of India.</p>
                  <li>Effective Date: </li>
                  <p>
                    Shall mean the date of submission of the duly filled Direct
                    Seller Application, subject to approval by Tirrent Global
                    LLC.
                  </p>
                </ol>
                <li className="term-conditions-order-list">
                  Applicant must submit the following:
                </li>
                <ol type="a">
                  <li>
                    Copy of the Government issued Identity Card. (Aadhar Card)
                  </li>
                  <li>Copy of Permanent Account Number (PAN) Card.</li>
                  <li>Copy of residential proof.</li>
                  <li>Passport size photograph.</li>
                  <li>
                    Terminated bank Cheque. (Any of the following document could
                    be furnished as proof of address; Aadhar Card/Voter
                    ID/DrivingLicense/Ration Card/Passport).
                  </li>
                  <h6>
                    <span>Note:</span>As a company, ensuring the privacy and
                    security of our customers' personal information is of utmost
                    importance to us. We have implemented strict measures and
                    policies to safeguard customer data and maintain
                    confidentiality.
                    <br />
                    We commit not to share any customer personal information
                    with any outside entities, unless required by law or with
                    the explicit consent of the customer. We strictly adhere to
                    applicable data protection and privacy laws and regulations.
                  </h6>
                </ol>
                <li className="term-conditions-order-list">
                  Cooling Off Period:
                </li>
                <p>
                  Newly joined Direct Seller shall have a cooling off period of
                  three (3) days to cancel the contract and receive full refund
                  for any unused and unpacked Tirrent Global products purchased
                  from Tirrent Global and such product or material is returned
                  in saleable, marketable condition.{" "}
                </p>
                <li className="term-conditions-order-list">
                  Legal Requiremen:
                </li>
                <p>
                  Applicant confirms that he/she is above the age of 18 years,
                  of sound mind and not disqualified from contracting by any
                  law. Applicant is entering into this contract with free
                  consent after undertaking mandatory orientation session about
                  direct selling and remuneration system of the Company.
                </p>
                <li className="term-conditions-order-list">Registration:</li>
                <p>
                  Company reserves the right, at its sole discretion, to accept
                  or refuse any application. Tirrent Global charge a fixed
                  registration, joining or renewal fee. Upon acceptance the
                  person will remain a direct Seller for a period of 24 months.
                  To keep the Direct Seller status beyond this period the Direct
                  Seller needs to demonstrate his/her activity during the past
                  12 months.
                </p>
                <li className="term-conditions-order-list">Duration</li>
                <p>
                  This Direct Seller Contract, shall remain valid and continue
                  to remain in full force unless terminated earlier by either
                  party with or without cause by giving notice according to the
                  provisions given in Policies and Procedure Rulebook.{" "}
                </p>
                <li className="term-conditions-order-list">
                  Distributorship/Direct Selling:
                </li>
                <p>
                  Tirrent Global appoints, without requirement/compulsion to buy
                  the product, as of the Effective Date, the individual(s)
                  identified in the above Direct Seller Application, or if
                  applicable, the legal entity listed therein (the “Entity”), as
                  a Direct Seller of Tirrent Global Products and services, and
                  the Applicant(s) agree(s) to such appointment. As of the
                  Effective Date and upon receipt of ordering information and
                  completion of any required formalities, the Direct Seller may,
                  on a Non-exclusive basis, within the Territory as may be
                  communicated by the Company, and otherwise in accordance with
                  the Direct Seller Contract, purchase Tirrent Global Products
                  from the Company in order to sell, distribute and market the
                  same, and also register Preferred Customers.
                </p>
                <li className="term-conditions-order-list">
                  Commission or Incentives:
                </li>
                <p>
                  Commission or incentive to Direct Seller are paid solely based
                  on the sale of products and the payment will be made only for
                  recruitment of new Direct Seller. Company has right to adjust
                  commission and bonuses paid on cancellation of Direct seller
                  in the down line.
                </p>
                <li className="term-conditions-order-list">
                  Obligation of Direct Sellers
                </li>
                <ol type="(1)">
                  <li>
                    Direct Seller shall carry the Identification card (ID Card)
                    issued by the Company and will seek prior appointment with
                    the customer for initiation of sale, Direct Seller shall
                    identify themselves and the Company, provide contact details
                    to customer and would truthfully represent the nature of
                    products in the manner consistent with the claims authorised
                    by the Company.{" "}
                  </li>
                  <li>
                    Direct Seller shall not use misleading deceptive or unfair
                    trade practices and not misrepresent actual or potential
                    sales/earning advantages of direct selling. Direct Seller
                    shall not make any false representation/promise relating to
                    direct selling, earning potential, remuneration system etc.
                  </li>
                  <li>
                    The Direct Seller shall, throughout the validity of this
                    Direct Seller Contract, strictly adhere to all applicable
                    laws, regulations and other legal obligations that affect
                    the operation of his/her/their business. The Direct Seller
                    shall be responsible for obtaining any applicable
                    registration, license, approval or authorisation, to carry
                    out the business as Direct Seller, copy of which shall be
                    provided to the Company upon request.
                  </li>
                  <li>
                    Direct Seller shall be guided by provisions of Consumer
                    Protection Act 1986 and shall comply with Direct Selling
                    guidelines 2016, be responsible for payment of any tax
                    liability on their earning and all local and municipal laws,
                    ordinances, rules, and regulations, guidelines and shall
                    make all reports and remit all withholdings or other
                    deductions as may be required by any such law.
                  </li>
                  <li>
                    Direct Seller would provide accurate and complete
                    explanations and demonstrations of goods, time and place to
                    inspect the sample and take delivery, prices, credit/payment
                    terms, terms of guarantee, after-sales service, goods return
                    policy, right to cancel the order and complaint redressal
                    mechanism of the Company.
                  </li>
                </ol>
                <li className="term-conditions-order-list">No Assignment:</li>
                <p>
                  Direct Seller shall not assign any rights or delegate his
                  duties under this Agreement without the prior written consent
                  of Company.{" "}
                </p>
                <li className="term-conditions-order-list">
                  No Employment Relationship:
                </li>
                <p>
                  Direct Seller confirms that he/she/they has/have entered into
                  this contract as an independent contractor.Nothing in this
                  agreement shall establish either an employment Relationship or
                  any other labor relationship between parties or a right for
                  Direct Seller to act as a procurer, broker, commercial agent,
                  contracting representative or other representative of the
                  Company.
                </p>
                <li className="term-conditions-order-list">
                  Right to Modification:
                </li>
                <p>
                  Company expressly reserve the right to alter, modify or amend
                  product prices, policies and procedure, product availability
                  and compensation plan, agreement through notices/News on its
                  website www.TirrentGlobal.com If the Direct Seller do not
                  agree to be bound by the said amendment he/she may terminate
                  the contract with immediate effect by giving a written notice
                  to the Company, otherwise Independent Distributor's continued
                  relationship with the company will constitute an affirmative
                  acknowledgment by the Direct Seller to having agreed to such
                  amendment and be bound by the same.{" "}
                </p>
                <li className="term-conditions-order-list">
                  Termination of Direct Seller Contact:
                </li>
                <p>
                  Company reserves the right to terminate the Direct Seller who
                  fails to adhere any of the terms and conditions/policies and
                  procedure of the Company
                </p>
                <li className="term-conditions-order-list">Advertisement:</li>
                <p>
                  Direct Seller is allowed to advertise the Tirrent Global
                  products on his personal digital & Social media channels,
                  however Direct Seller is strictly prohibited from selling or
                  promoting company products on internet shopping or auction
                  sites.
                </p>
                <li className="term-conditions-order-list">
                  Sever ability Clause:
                </li>
                <p>
                  If any provision of these Terms and Conditions is declared
                  invalid or unenforceable, the remaining provisions shall
                  remain in full force and effect.
                </p>
                <li className="term-conditions-order-list">Governing Law:</li>
                <p>
                  The Direct Seller Contact and all questions of its
                  interpretation shall be governed by and construed in
                  accordance with the laws of Republic of India, without regards
                  to its principles of conflicts of laws. The Agreement is civil
                  in nature and hence, it is to be governed and construed in
                  accordance with the IndianContract Act, 1872, the Code of
                  Civil Procedure and other applicable laws of India.
                </p>
                <li className="term-conditions-order-list">
                  Dispute Settlement:
                </li>
                <p>
                  Any dispute arising out of this agreement or in any manner
                  touching upon it, the same shall be settled through
                  arbitration under Arbitration and Conciliation Act 1996 with
                  all statutory amendments, by a sole arbitrator to be appointed
                  by a Director of the Company, who may be specifically
                  authorised by the Board of Directors of the Company in this
                  regard. The venue of arbitrator shall be Bangalore.{" "}
                </p>
                <li className="term-conditions-order-list">
                  Limitation of Liability:
                </li>
                <p>
                  Companies Liability whether under contract or otherwise,
                  arising out of or in connection with this contract shall not
                  exceed the less of{" "}
                </p>
                <ul>
                  <li>Actual damages or loss accessed by the arbitrator.</li>

                  <li>
                    The total commission earned by the Direct Seller during the
                    six months’ period preceding the date of the dispute
                  </li>
                </ul>
                <p>
                  Insistence: The Direct Seller Terms and Condition constitutes
                  the contractual offer of the undersigned individual addressed
                  to Tirrent Global LLC. (Tirrent Global) to enter into a
                  ‘Direct Seller” agreement with Tirrent Global LLC. and under
                  the company policy, procedures, terms & condition specified
                  herewith/in the application form/below. The applicant hereby
                  certifies that he/she/M/s. are legally competent to do
                  business in India and not bound by any legal requirement
                  restricting or prohibiting his/her appointment as an Tirrent
                  Global LLC. Company’s Direct Seller. By signing this terms and
                  condition, I/We confirm that I/We have been provided with or
                  have undergone orientation programme which provided fair and
                  accurate information on all aspects of Tirrent Global LLC.
                  company's direct selling operation, about joining, its
                  remuneration system, its refund and return policy, expected
                  remuneration and related rights and obligations as governed
                  under the Tirrent Global Policy and Procedures, Code of
                  Ethics, Direct Seller Orientation Programme. I/We do hereby
                  declare that the information finished above is true and I/We
                  legally competent to enter into Direct Seller Contract and
                  signing it under my own free will.
                </p>
              </ol>
              <p>
                Tirrent Global LLC is marketing its consumer products under
                Direct Selling System. an opportunity is given to devoted
                persons to make the purchases as per the plan of the company by
                helping others as the benefits of all are interrelated. Each
                individual associates himself with the company and purchases the
                goods directly from company. The customer pays the price and is
                given few discount. Direct sellers of Tirrent Global work on
                their own, but affiliate with company and create a channel,
                retaining the freedom to run a business on the terms of Tirrent
                Global . Consultants forge strong relationships with prospective
                customers, primarily through face-to-face discussions and
                demonstrations. Consumers purchase products not due to
                advertisement but due to quality of the product or due to
                satisfaction and verbal opinion of the satisfied consumers.
                After using the product customers promote the product verbally
                share its views on quality and satisfaction and thus day by day
                Tirrent Global group expands. Tirrent Global doesn’t require any
                advertisement promotion, any wholesale marketing agent, any area
                distributor or retailer for selling the products. Company saves
                lot of amount and distributes it to Associate Buyers as per the
                plan.
              </p>
              <p>
                An observance of the above would show that the whole marketing
                system isn’t based on any promotional advertisement, but on
                creating an acceptability and awareness amongst the community ,
                by actual use of the products by some members of the society and
                deriving satisfaction out of the same. For example, if someone
                purchases a product from Tirrent Global and feels satisfied with
                quality and usefulness of the same, he is expected to tell this
                to others, so that others would also follow his example and
                purchase the product. However, it may be noted that the person
                is not under any obligation to do so.
              </p>

              <p>
                In reference to this, the persons may be interested in sharing
                their view about the product of Tirrent Global , has devised
                method by which it is identified that some people have purchased
                the goods due to sharing of the views about the goods by someone
                gets stimulation/discount. Thus, in this way, group for making
                the purchases is formed on its own.Any person who is interested
                to avail the incentive on purchase as per defined methodology
                confined by the company, has to get himself registered as a
                Direct Seller with Tirrent Global and follow other courses.
              </p>

              <h2>
                List of the documents to be uploaded along with the
                registration/application form becoming a direct seller are as
                follows:
              </h2>
              <ol>
                <li DEFINITIONS AND INTERPRETATION>
                  A coloured passport size photo.{" "}
                </li>
                <li>
                  One of the following documents for certification of Photo ID
                  <ol type="a">
                    <li>Aadhaar Card (Both Side)</li>
                    <li>PAN Card</li>
                    <li>Passport (Valid)</li>
                    <li>Voter’s Identity Card</li>
                    <li>Driving License (Valid)</li>
                    <li>
                      Written confirmation from the banks certifying identity
                      proof
                    </li>
                    <li>
                      Domicile certificate with communication address and
                      photograph
                    </li>
                    <li>Central/State Government certified ID proof</li>
                    <li>
                      Certification from any of the Authorities mentioned below:
                      <ol>
                        <li>Panchayat Pradhan</li>
                        <li>Councillor</li>
                        <li>Sarpanch of Gram Panchayat</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                <b>Note:</b> Expired document ( Passport, Driving licence , etc)
                cannot be used as address proof, only can be accepted as ID
                proof.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TermsAndCondtion;
