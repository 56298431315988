import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
function Suppliers() {
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area title-area-supplier">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Our Suppliers</h2>
            <div className="container">
              <div className="title-item">
                <h2>Our Suppliers</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>Suppliers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="service-area-two service-area-three pt-100 pb-70 our-suppliers">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>

                <h3>
                  <a>Mahalaxmi Oil Suppliers</a>
                </h3>
                <p>Kanpur, Uttar Pradesh, India</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>
                <h3>
                  <a>Vinayak Oil Suppliers</a>
                </h3>
                <p>Mumbai, Maharashtra, India</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>

                <h3>
                  <a>Vardhman Oil Suppliers</a>
                </h3>
                <p>Kolkata, West Bengal, India</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>
                <h3>
                  <a>Alata Oil Partners</a>
                </h3>
                <p>Pune, Maharashtra </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>

                <h3>
                  <a>Ridhi Sidhi Oil Suppliers</a>
                </h3>
                <p>Ahmedabad, Gujarat, India</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <i className="fa fa-building"></i>

                <h3>
                  <a>Unique Oil</a>
                </h3>
                <p>Mansarovar, Jaipur </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Suppliers;
