function useApiFunction() {
  const fetchApi = async (url, method, body = {}) => {
    let token = sessionStorage.getItem("token");

    try {
      if (token) {
        let res =
          method == "POST"
            ? await fetch(`${process.env.REACT_APP_API_HOST_TESTING}/${url}`, {
                method: method,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${token}`,
                },
                body: JSON.stringify(body),
              })
            : await fetch(`${process.env.REACT_APP_API_HOST_TESTING}/${url}`, {
                method: method,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${token}`,
                },
              });

        res = await res.json();
        return res;
      } else {
        let res = await fetch(
          `${process.env.REACT_APP_API_HOST_TESTING}/${url}`,
          {
            method: method,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        res = await res.json();
        return res;
      }
    } catch (error) {
    }
  };
  return { fetchFunc: fetchApi };
}

export default useApiFunction;
