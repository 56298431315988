import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs8() {
  const { t } = useTranslation(["Blogs9"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t("img")}`}
                    width="100%"
                  />

                  <h2>{t("heading")}</h2>
                  <p>{t("para")}</p>

                  <ul className="blog6-point">
                    <li> {t("head1")}</li>
                    <p>{t("para1")}</p>

                    <li> {t("head2")}</li>
                    <p>{t("para2")}</p>

                    <li> {t("head3")}</li>
                    <p>{t("para3")}</p>

                    <li> {t("head4")}</li>
                    <p>{t("para4")}</p>

                    <li> {t("head5")}</li>
                    <p>{t("para5")}</p>

                    <li> {t("head6")}</li>
                    <p>{t("para6")}</p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs8;
