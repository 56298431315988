import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import useApiFunction from "../Shared/useApiFunction";

function Profile() {
  const [data, setData] = useState("");
  const { fetchFunc } = useApiFunction();

  const fetchPreviousOrder = async () => {
    const res = await fetchFunc("get_user", "GET", {});

    if (res.status) {
      setData(res.msg);
    } else {
      toast.warning(res.msg, { autoclose: 2000 });
    }
  };

  useEffect(() => {
    fetchPreviousOrder();
  }, []);
  return (
    <>
      <Header />
      <section className="about-area profile-sec">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-md-12">
              <div id="card" className="mt-5 mb-5">
                <div className="profile-cover">
                  <div className="coverimg w-100 h-300 mb-0 position-relative text-center">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-logo.png"
                      width="30%"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12 top-80 avatar avatar-160">
                    <div className="d-flex justify-content-center">
                      <img
                        src="SiteTemplate/TirrentGlobal/assets/img/logo----.png"
                        alt="avatar"
                        style={{ border: "2px dashed #fff" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-9 col-sm-12">
                    <div className="info">
                      <p className="name">Dev kishan </p>
                      <div className="row" id="stats">
                        <div className="col-md-6  stats-text">
                          <i className="fa fa-phone"></i>
                          <span>+91-9928136795</span>
                        </div>
                        <div className="col-md-6  stats-text">
                          <i className="fa fa-envelope"></i>
                          <span>tirrentglobal@gmail.com</span>
                        </div>
                        <div className="col-md-6  stats-text">
                          <i className="fa fa-users"></i>
                          <span>
                            Total Referrals :
                            <span style={{ color: "#F99F36" }}>2</span>
                          </span>
                        </div>
                        <div className="col-md-6  stats-text">
                          <i className="fa-solid fa-cart-shopping"></i>
                          <span>
                            Total Order :
                            <span style={{ color: "#F99F36" }}>2098000</span>
                          </span>
                        </div>
                        <div className="col-md-12">
                          <div className="searchbox-wrap">
                            <input
                              type="text"
                              value="https://tirrentglobal.com/product-details?refid=4*********"
                              placeholder="Search for something..."
                            />
                            <button className="buy-btn link-btn">
                              <span>Referral Link Copy</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Profile;
