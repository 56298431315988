import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SiteRoute from "./SiteTemplates/TirrentGlobal/SiteRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<SiteRoute />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
