import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Faq() {
  const [active, setActive] = useState("");
  const { pathname } = useLocation();
  const { t } = useTranslation(["Faq"]);

  function click(str) {
    setActive(str);
  }
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container mt-5">
              <div className="title-item">
                <h2>{t("heading")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" service-area-three pt-100 pb-70">
        <div className="container">
          <div className="row faq-container">
            <div className="col-md-6">
              {" "}
              <div
                className={active === "ques1" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques1");
                }}
              >
                <h3 className="faq-title">{t("q1.question")}</h3>
                <p className="faq-text">{t("q1.answer.para1")}</p>
                <p className="faq-text">{t("q1.answer.para1")}</p>
                <div className="row">
                  <div className="col-lg-6 faq-img">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue11.jpg"
                      width="60%"
                      className="faq-text"
                    />
                  </div>

                  <div className="col-lg-6 faq-img">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue12.png"
                      width="60%"
                      className="faq-text"
                    />
                  </div>
                </div>

                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques1");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques2");
                  }}
                >
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
              <div
                className={active === "ques2" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques2");
                }}
              >
                <h3 className="faq-title">{t("q2.question")}</h3>
                <p className="faq-text">{t("q2.answer.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques2");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
              <div
                className={active === "ques4" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques4");
                }}
              >
                <h3 className="faq-title">{t("q3.question")}</h3>
                <p className="faq-text">{t("q3.answer.para1")}</p>
                <p className="faq-text">{t("q3.answer.para2")}</p>
                <p className="faq-text">{t("q3.answer.para3")}</p>
                <div className="row">
                  <div className="col-lg-6 faq-img">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue31.png"
                      width="60%"
                      className="faq-text"
                    />
                  </div>
                  <div className="col-lg-6 faq-img">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue32.png"
                      width="60%"
                      className="faq-text"
                    />
                  </div>
                </div>
                <h5 className="faq-text main-text">
                  {t("q3.answer.para4.heading")}
                </h5>

                <ul className="mt-2 faq-text ">
                  <li>{t("q3.answer.para4.points.1")}</li>
                  <li>{t("q3.answer.para4.points.2")}</li>
                  <li>{t("q3.answer.para4.points.3")}</li>
                  <li>{t("q3.answer.para4.points.4")}</li>
                  <li>{t("q3.answer.para4.points.5")}</li>
                  <li>{t("q3.answer.para4.points.6")}</li>
                  <li>{t("q3.answer.para4.points.7")}</li>
                </ul>
                <h5 className="faq-text main-text">
                  {t("q3.answer.para5.heading")}
                </h5>
                <p className="faq-text">{t("q3.answer.para5.para1")}</p>
                <p className="faq-text">
                  {t("q3.answer.para5.para2")}
                  <br />

                  {t("q3.answer.para5.para3")}
                </p>

                <p className="faq-text">{t("q3.answer.para5.para4")}</p>
                <p className="faq-text">{t("q3.answer.para5.para5")}</p>
                <h5 className="faq-text main-text">
                  {t("q3.answer.para6.heading")}
                </h5>
                <ul className="faq-text">
                  <li>
                    {t("q3.answer.para6.1.heading")}
                    <p>{t("q3.answer.para6.1.para")}</p>
                  </li>
                  <li>
                    {t("q3.answer.para6.2.heading")}
                    <p>{t("q3.answer.para6.2.para")}</p>
                    <div className="row">
                      <div className="col-lg-10 faq-img">
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue33.jpg"
                          width="60%"
                          className="faq-text"
                        />
                      </div>
                    </div>
                  </li>

                  <li>
                    {t("q3.answer.para6.3.heading")}
                    <p>{t("q3.answer.para6.3.para")}</p>
                    <div className="row">
                      <div className="col-lg-12 faq-img">
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue34.jpg"
                          width="60%"
                          className="faq-text"
                        />
                      </div>
                    </div>
                  </li>

                  <li>
                    {t("q3.answer.para6.4.heading")}
                    <p>{t("q3.answer.para6.4.para")}</p>
                    <div className="row">
                      <div className="col-lg-10 faq-img">
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue35.jpg"
                          width="60%"
                          className="faq-text"
                        />
                      </div>
                    </div>
                  </li>
                </ul>

                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques4");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
              <div
                className={active === "ques3" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques3");
                }}
              >
                <h3 className="faq-title">{t("q4.question")}</h3>
                <p className="faq-text">{t("q4.para1")}</p>

                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques3");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
              <div
                className={active === "ques7" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques7");
                }}
              >
                <h3 className="faq-title">{t("q5.question")}</h3>
                <p className="faq-text">{t("q5.para")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques7");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques6" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques6");
                }}
              >
                <h3 className="faq-title">{t("q6.question")}</h3>
                <p className="faq-text">{t("q6.para1")}</p>
                <p className="faq-text">{t("q6.para2")}</p>
                <h5 className="faq-text main-text">{t("q6.para3.heading")}</h5>
                <ul className="faq-text">
                  <li>
                    {t("q6.para3.1.heading")}
                    <p>{t("q6.para3.1.para")}</p>
                  </li>
                  <li>
                    {t("q6.para3.2.heading")}
                    <p>{t("q6.para3.2.para")}</p>
                  </li>
                  <li>
                    {t("q6.para3.3.heading")}
                    <p>{t("q6.para3.3.para")}</p>
                  </li>
                  <li>
                    {t("q6.para3.4.heading")}
                    <p>{t("q6.para3.4.para")}</p>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-lg-12 faq-img">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/Faq-img/faqQue51.jpg"
                      width="30%"
                      className="faq-text"
                    />
                  </div>
                </div>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques6");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques8" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques8");
                }}
              >
                <h3 className="faq-title">{t("q8.question")}</h3>
                <p className="faq-text">{t("q8.para1")}</p>
                <p className="faq-text">{t("q8.para2")}</p>
                <ul className="faq-text">
                  <li>
                    {t("q8.para3.1.heading")}
                    <p>{t("q8.para3.1.para")}</p>{" "}
                  </li>
                  <li>
                    {t("q8.para3.2.heading")}
                    <p>{t("q8.para3.2.para")}</p>
                  </li>
                  <li>
                    {t("q8.para3.3.heading")}
                    <p>{t("q8.para3.3.para")}</p>
                  </li>
                  <li>
                    {t("q8.para3.4.heading")}
                    <p>{t("q8.para3.4.para")}</p>
                  </li>
                  <li>
                    {t("q8.para3.5.heading")}
                    <p>{t("q8.para3.5.para")}</p>
                  </li>
                  <li>
                    {t("q8.para3.6.heading")}
                    <p>{t("q8.para3.6.para")}</p>
                  </li>
                </ul>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques8");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
            </div>

            <div className="col-md-6">
            
              {/* FAQ9 */}
              <div
                className={active === "ques9" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques9");
                }}
              >
                <h3 className="faq-title">{t("q9.question")}</h3>
                <p className="faq-text">{t("q9.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques9");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques10" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques10");
                }}
              >
                <h3 className="faq-title">{t("q10.question")}</h3>
                <p className="faq-text">{t("q10.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques10");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques11" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques11");
                }}
              >
                <h3 className="faq-title">{t("q11.question")}</h3>
                <p className="faq-text">{t("q11.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques11");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques12" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques12");
                }}
              >
                <h3 className="faq-title">{t("q12.question")}</h3>
                <p className="faq-text">{t("q12.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques12");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques13" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques13");
                }}
              >
                <h3 className="faq-title">{t("q13.question")}</h3>
                <p className="faq-text">{t("q13.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques13");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques14" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques14");
                }}
              >
                <h3 className="faq-title">{t("q14.question")}</h3>
                <p className="faq-text">{t("q14.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques14");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>

              <div
                className={active === "ques15" ? "faq active" : "faq"}
                onClick={() => {
                  click("ques15");
                }}
              >
                <h3 className="faq-title">{t("q15.question")}</h3>
                <p className="faq-text">{t("q15.para1")}</p>
                <button
                  className="faq-toggle"
                  onClick={() => {
                    click("ques15");
                  }}
                >
                  <i
                    className="fa-solid fa-chevron-down"
                    style={{ color: "#fff" }}
                  ></i>
                  <i
                    className="fa-solid fa-times"
                    style={{ color: "#fff" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Faq;
