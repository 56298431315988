import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function ResearchPage() {
  const { t } = useTranslation(["research"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("ResearchHeading.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h1 className="research-heading">
                  {t("researchHeading.title1")}
                </h1>
              </div>
              <div className="about-content">
                <p>{t("researchHeading.line2")}</p>
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title3")}
                </h4>

                <p>{t("researchHeading.line3")}</p>
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title4")}
                </h4>
                <p>{t("researchHeading.line4")}</p>

                <ul>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>
                    {t("researchHeading.subheading1.title")}
                    <p className="para">
                      {t("researchHeading.subheading1.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading2.title")}
                    <p className="para">
                      {t("researchHeading.subheading2.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading3.title")}
                    <p className="para">
                      {t("researchHeading.subheading3.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading4.title")}
                    <p className="para">
                      {t("researchHeading.subheading4.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading5.title")}
                    <p className="para">
                      {t("researchHeading.subheading5.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading6.title")}
                    <p className="para">
                      {t("researchHeading.subheading6.description")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 research-development">
              <div className="about-img-wrap">
                <div className="about-img-slider owl-theme">
                  <div className="about-img-item">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/tecno-page.png"
                      alt="About"
                    />
                  </div>
                </div>

                <div className="about-year">
                  <h2>
                    <span>{t("ResearchHeading.search")}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="project-details-area">
        <div className="container">
          <div className="row">
            <div className="project-details-content">
              <div className="row">
                <div className="col-sm-6 col-lg-6 mt-5">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/research1.jpg"
                    alt="Project Details"
                  />
                </div>
                <div className="col-sm-6 col-lg-6 mt-5">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/research3.jpg"
                    alt="Project Details"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-item">
                <div className="project-details-img">
                  <div className="section-title mt-5">
                    <h1 className="research-heading">{t("Product.title")}</h1>
                  </div>

                  <p>{t("Product.line1")}</p>
                  <p> {t("Product.line2")}</p>
                  <p> {t("Product.line3")}</p>
                  <h2 className="product-r-d">{t("engine.title")}</h2>

                  <p>{t("engine.line1")}</p>
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <ul>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point1")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point2")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point3")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point4")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point9")}
                        </li>{" "}
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point10")}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <ul>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point5")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point6")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point7")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point8")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point11")}
                        </li>{" "}
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point12")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResearchPage;
