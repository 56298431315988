import React from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "../Shared/Modal";

function Gallery() {
  const { t } = useTranslation(["Gallery"]);
  const navigate=useNavigate()


  

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("Gallery.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="offer-area pt-100 pb-100">
        <div className="container our-technology">
          <section class="gallery">
            <div class="container1">
              <div
               
                className="grid"
              >
                <div class="column-xs-12 column-md-4" onClick={()=>navigate('/events/abu-dhabi')}>
                  <img className="img1" src="SiteTemplate/TirrentGlobal/assets/img/gallery/Bikes.jpeg" />
                  <h5 className="events-name">Abu Dhabi</h5>
                </div>
                <div class="column-xs-12 column-md-4" onClick={()=>navigate('/events/india')}>
                  <img className="img1" src="SiteTemplate/TirrentGlobal/assets/img/gallery/Cars.jpeg" />
                  <h5 className="events-name">India</h5>
                </div>
                <div class="column-xs-12 column-md-4" onClick={()=>navigate('/events/australia')}>
                  <img className="img1" src="SiteTemplate/TirrentGlobal/assets/img/gallery/Deserts.jpeg" />
                  <h5 className="events-name">Australia</h5>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* </div> */}
      </section>
      <Footer />
    </>
  );
}

export default Gallery;
