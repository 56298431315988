import React, { createContext, useEffect, useState } from "react";

export const locationContext = createContext();

function LocationContext({ children }) {
  const [accessToBuyProduct, setAccessToBuyProduct] = useState(false);

  // const success = async (position) => {
  //   const { latitude, longitude } = position.coords;
  //   try {
  //     let response = await fetch(
  //       `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=`
  //     );
  //     response = await response.json();
  //     console.log(response, "response");
  //     if (response.status.code == 200) {
  //       const finalResponse = response.results[0].components.country == "India";
  //       setAccessToBuyProduct(finalResponse);
  //     } else {
  //       console.log(
  //         "Something went wrong at the time of fetching opencage api --> ",
  //         response
  //       );
  //     }
  //   } catch (error) {
  //     console.log(`Error in opencage api --> `, error);
  //   }
  // };

  // const failed = (error) => {
  //   console.log("error in geolocation api --> ", error);
  // };

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         if (result.state === "granted") {
  //           setMsg("granted");
  //           navigator.geolocation.getCurrentPosition(success, failed);
  //         } else if (result.state === "denied") {
  //           setMsg("denied");
  //           console.log("Please enable your location first from the settings");
  //         }
  //       });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // };

  const getLocation = async () => {
    try {
      await fetch("https://ipapi.co/json/")
        .then((data) => data.json())
        .then((result) =>
          result.country_name === "India"
            ? setAccessToBuyProduct(true)
            : setAccessToBuyProduct(false)
        );
    } catch (error) {
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <locationContext.Provider value={{ accessToBuyProduct }}>
      {children}
    </locationContext.Provider>
  );
}

export default LocationContext;
