import React, { useState } from "react";
import Header from "../Shared/Header";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Shared/Footer";
import Modal from "../Shared/Modal";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function GalleryEvents() {
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);

  const responsive = {
    0: { items: 1 },
    350: { items: 1 },
    700: { items: 1 },
    1050: { items: 1 },
  };

  const Toggle = () => setToggle((prv) => !prv);
  const dataImg = [
    [
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Bikes.jpeg",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Cars.jpeg",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Deserts.jpeg",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Heavy Machines.jpeg",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Marine.jpeg",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/1.png",
    ],
    [
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/2.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/3.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/4.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/5.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/6.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/Riders.jpeg",
    ],
    [
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/7.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/8.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/9.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/10.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/11.png",
      "/SiteTemplate/TirrentGlobal/assets/img/gallery/12.png",
    ],
  ];

  const { t } = useTranslation(["Gallery"]);
  const result = id == "india" ? 1 : id == "abu-dhabi" ? 0 : 2;
  const [currentCountry, setCurrentCountry] = useState(result);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const items = [];
  dataImg[currentCountry].map((data) => {
    items.push(
      <div className="" style={{ display: "flex", justifyContent: "center" }}>
        <img src={data} width="75%" />
      </div>
    );
  });

  return (
    <>
      <Header />
      <div className="page-title-area gallery-banner">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Events Pictures</h2>
            <div className="container">
              <div className="title-item">
                <h2>Events Pictures</h2>
                <ul>
                  <li>
                    <Link to="/">{t("Gallery.description")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("Gallery.title")} / Events</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="offer-area pt-100 pb-100">
        <div className="container our-technology">
          <section className="offer-area pt-100 pb-100">
            <div className="container our-technology">
              <div class="section-title">
                <h2>{id}</h2>
              </div>
              <section class="gallery">
                <div class="container1">
                  <div className="grid">
                    {dataImg[currentCountry].map((data, index) => {
                      return (
                        <div
                          class="column-xs-4 column-md-3"
                          id={`${data + Math.random()}`}
                          onClick={(e) => {
                            Toggle(e);
                            setCurrentImageIndex(index);
                          }}
                        >
                          <img className="img1" src={data} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
            {/* </div> */}
          </section>
        </div>
        {/* </div> */}
      </section>
      <Footer />
      {toggle && (
        <Modal show={toggle} close={Toggle}>
          <AliceCarousel
            activeIndex={currentImageIndex}
            items={items}
            responsive={responsive}
            infinite={true}
            fadeOutAnimation={true}
            disableDotsControls={true}
            controlsStrategy="alternate"
          />
        </Modal>
      )}
    </>
  );
}

export default GalleryEvents;
