import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import ProductBooster from "./ProductBooster";
import ProductMileage from "./ProductMileage";
import ProductSpray from "./ProductSpray";
import ProductGrease from "./ProductGrease";
import { toast, ToastContainer } from "react-toastify";

function ProductPage() {
  const { id } = useParams();

  let result =
    id == "tirrent-booster"
      ? 0
      : id == "tirrent-fuel-conditioner"
      ? 1
      : id == "tg-10"
      ? 2
      : 3;

  const [tabIndex, setTabIndex] = useState(result);
  const { t } = useTranslation(["Product"]);
  const { pathname } = useLocation();
  const Components = [
    <ProductBooster />,
    <ProductMileage />,
    <ProductSpray />,
    <ProductGrease />,
  ];

  const handleClickOnTab = (e) => {
    result =
      e.target.id == "tirrent-booster"
        ? 0
        : e.target.id == "tirrent-mileage-booster"
        ? 1
        : e.target.id == "tirrent-magic-spray"
        ? 2
        : 3;
    setTabIndex(result);
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
     
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
           
            {Components[tabIndex]}
          </div>
        </div>
      </section>
      

      <Footer />
      <ToastContainer />
    </>
  );
}

export default ProductPage;
