import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";
import { locationContext } from "../Context/LocationContext";

function ProductDetails() {
  const { t } = useTranslation(["ProductDetails"]);
  const { accessToBuyProduct } = useContext(locationContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [imgTab, setImgTab] = useState("");
  const [prvData, setPrvData] = useState("");

  const [quentity, setQuentity] = useState(1);
  const images = [
    <div
      className="container-product"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="imgBx" style={{ overflow: "hidden" }}>
        <img
          src={`/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png`}
          alt="img"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>,
    <div
      className="container-product"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="imgBx" style={{ overflow: "hidden" }}>
        <img
          src={`/SiteTemplate/TirrentGlobal/assets/img/productss/2-logo.png`}
          alt="img"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>,
    <div
      className="container-product"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="imgBx" style={{ overflow: "hidden" }}>
        <img
          src={`/SiteTemplate/TirrentGlobal/assets/img/productss/3-logo.png`}
          alt="img"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>,
    <div
      className="container-product"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="imgBx" style={{ overflow: "hidden" }}>
        <img
          src={`/SiteTemplate/TirrentGlobal/assets/img/productss/4-logo.png`}
          alt="img"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>,
    <div
      className="container-product"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="imgBx" style={{ overflow: "hidden" }}>
        <img
          src={`/SiteTemplate/TirrentGlobal/assets/img/productss/5-logo.png`}
          alt="img"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>,
  ];
  const techImage = {
    0: { items: 1 },
  };
  const cart = () => {
    const getItems = localStorage.getItem("cart");

    if (getItems) {
      const items = JSON.parse(getItems);

      const length = items?.length;
      localStorage.setItem(
        "cart",
        JSON.stringify([
          ...items,
          {
            Id: length,
            Image:
              "/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png",
            ProductName: "Tirrent Booster",
            Size: "250",
            Quantity: quentity,
            Discount: `${quentity * t("Product.price")} `,
            TotalAmount: `${quentity * t("Product.mrprate")}`,
          },
        ])
      );
    } else {
      localStorage.setItem(
        "cart",
        JSON.stringify([
          {
            Id: 0,
            Image:
              "/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png",
            ProductName: "Tirrent Booster",
            Size: "250",
            Quantity: quentity,
            Discount: `${quentity * t("Product.price")} `,
            TotalAmount: `${quentity * t("Product.mrprate")}`,
          },
        ])
      );
    }

    navigate("/cart", { state: { Quentity: quentity } });
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (searchParams.get("refid")) {
      let newdata = searchParams.get("refid");
      const token = sessionStorage.getItem("token");
      newdata = JSON.parse(newdata);

      setPrvData(newdata);

      if (!token) {
        navigate("/login", {
          state: { path: "product-details", referral: newdata },
        });
      }
    }
  }, []);

  return (
    <>
      <Header />

      <div className="container">
        <div
          className="row"
          style={{ marginTop: "0px", padding: "150px 0px 50px 0px" }}
        >
          <div className="col-md-1 imagesTab">
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/productss/1-logo.png"
              alt="img"
              width="100%"
            />
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/productss/2-logo.png"
              alt="img"
              width="100%"
            />
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/productss/3-logo.png"
              alt="img"
              width="100%"
            />
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/productss/4-logo.png"
              alt="img"
              width="100%"
            />
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/productss/5-logo.png"
              alt="img"
              width="100%"
            />
          </div>
          <div className="col-lg-6">
            <AliceCarousel
              items={images}
              responsive={techImage}
              controlsStrategy="alternate"
              autoPlay={true}
              autoPlayInterval={1200}
              infinite={true}
              animationDuration={1500}
              fadeOutAnimation={true}
              disableDotsControls={true}
            />
          </div>
          <div className="col-lg-5 tirrent-booster product-title">
            <h5>
              <span className="product-booster">{t("Product.title")} </span>(
              {t("Product.span")})
            </h5>

            <div className="rating d-flex">
              <span> {t("Product.rate")}</span>&nbsp;
              <i className="fa fa-star" style={{ color: "#f4a340" }}></i>
              <i className="fa fa-star" style={{ color: "#f4a340" }}></i>
              <i className="fa fa-star" style={{ color: "#f4a340" }}></i>
              <i className="fa fa-star" style={{ color: "#f4a340" }}></i>
              <i className="fa fa-star-half" style={{ color: "#f4a340" }}></i>
              <h6>
                {" "}
                &nbsp;&nbsp;{t("Product.rating")} &nbsp; | &nbsp;
                {t("Product.que")}
              </h6>
            </div>
            <div className="mt-3">
              <span className="product-booster">{t("Product.des-title")}</span>
              <p>{t("Product.des")}</p>
              <hr />
              <div
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <h1>
                    {t("Product.rs")}
                    {t("Product.price")}
                  </h1>
                  <h6 style={{ color: "#565959" }}>
                    {t("Product.mrp")}{" "}
                    <del>
                      {t("Product.rs")}
                      {t("Product.mrprate")}
                    </del>{" "}
                  </h6>
                  <p style={{ marginBottom: "0" }}>{t("Product.tax")}</p>
                </div>
                <div className="d-flex gap-5">
                  <h6 className="mt-1">
                    {t("Product.qut")} <br />
                    <button
                      className="mt-2"
                      style={{
                        border: "2px solid #f99f36",
                        backgroundColor: "transparent",
                        padding: "5px 10px",
                      }}
                    >
                      {t("Product.ml")}
                    </button>
                  </h6>
                  <div className="">
                    <h5 className="mt-1">
                      {" "}
                      {t("Product.textqut")}&nbsp;&nbsp;
                    </h5>
                    <select
                      name="select"
                      onChange={(e) => setQuentity(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <span className="product-booster">{t("Product.usage-title")}</span>
            <p>{t("Product.usage")}</p>
            <hr />
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="text-center">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/fea/shield.png"
                  alt="img"
                  width="25%"
                />
                <p>{t("Product.warrenty")} </p>
              </div>
              <div className="text-center">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/fea/truck.png"
                  alt="img"
                  width="25%"
                />
                <p>{t("Product.delivery")}</p>
              </div>
              <div className="text-center">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/fea/brand.png"
                  alt="img"
                  width="25%"
                />
                <p>{t("Product.brand")}</p>
              </div>
              <div className="text-center">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/fea/non-returnable.png"
                  alt="img"
                  width="25%"
                />
                <p>{t("Product.returnable")}</p>
              </div>
            </div>
            {accessToBuyProduct && (
              <div className="d-grid mb-3">
                <button
                  className=""
                  style={{
                    backgroundColor: "#F99F36",
                    color: "#fff",
                    borderRadius: "10px",
                    border: "none",
                    padding: "10px",
                  }}
                  onClick={() => cart()}
                >
                  {t("Product.add")}
                </button>
                <button
                  className="mt-3"
                  style={{
                    backgroundColor: "#1C1B19",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() =>
                    navigate("/payment-method", {
                      state: { quentity: quentity, previousData: prvData },
                    })
                  }
                >
                  {t("Product.buy")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductDetails;
