import React from "react";
import { toast, ToastContainer } from "react-toastify";

function UserInfoPayment() {
  return (
    <>
      <div className="mb-2">
        <div className="adderess-card w-100">
          <div className="adderess-section">
            <div className="ticket-body">
              <h4 className="ticket-title">Order Details</h4>
              <div className="order-detail mb-4">
                <div className="">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/bottle-1.png"
                    width="100%"
                    alt="img"
                    className=""
                  />
                </div>
                <div className="">
                  <table className="table table-section">
                    <tr>
                      <th>Product Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <td>Tirrent Booster</td>
                    </tr>
                    <tr>
                      <th>Size: </th>
                      <td>250ML</td>
                    </tr>
                    <tr>
                      <th>Quantity:</th>
                      <td>2</td>
                    </tr>
                    <tr>
                      <th>Discount:</th>
                      <td>rs 1001</td>
                    </tr>
                    <tr>
                      <th>Total Amount:</th>
                      <td>3,999</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="order-detail mb-4">
                <div className="">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/bottle-1.png"
                    width="100%"
                    alt="img"
                    className=""
                  />
                </div>
                <div className="">
                  <table className="table table-section">
                    <tr>
                      <th>Product Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <td>Tirrent Booster</td>
                    </tr>
                    <tr>
                      <th>Size: </th>
                      <td>250ML</td>
                    </tr>
                    <tr>
                      <th>Quantity:</th>
                      <td>1</td>
                    </tr>
                    <tr>
                      <th>Discount:</th>
                      <td>rs 1001</td>
                    </tr>
                    <tr>
                      <th>Total Amount:</th>
                      <td>3,999</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="order-detail mb-4">
                <div className="">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/bottle-1.png"
                    width="100%"
                    alt="img"
                    className=""
                  />
                </div>
                <div className="">
                  <table className="table table-section">
                    <tr>
                      <th>Product Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <td>Tirrent Booster</td>
                    </tr>
                    <tr>
                      <th>Size: </th>
                      <td>250ML</td>
                    </tr>
                    <tr>
                      <th>Quantity:</th>
                      <td>2</td>
                    </tr>
                    <tr>
                      <th>Discount:</th>
                      <td>rs 1001</td>
                    </tr>
                    <tr>
                      <th>Total Amount:</th>
                      <td>3,999</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default UserInfoPayment;
