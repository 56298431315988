import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

function Club() {
  return (
    <div>
      <Header />
      <div className="page-title-area title-area-about testimonails">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Our Happy Clients</h2>
            <div className="container">
              <div className="title-item">
                <h2>Our Happy Clients</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>About the Company</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="testimonials">
        <div className="testimonial-heading">
          <h4>Our Happy Clients</h4>
        </div>
        <div className="testimonial-box-container">
          <div className="testimonial-box">
            <div className="box-top">
              <div className="profile">
                <div className="profile-img">
                  <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                </div>
                <div className="name-user">
                  <strong>Liam mendes</strong>
                  <span>@liammendes</span>
                </div>
              </div>
            </div>
            <div className="client-comment">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Exercitationem, quaerat quis? Provident temporibus architecto
                asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam
                tenetur voluptates incidunt blanditiis sed atque cumque.
              </p>
            </div>
            <div className="reviews">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
            </div>
          </div>
          <div className="testimonial-box">
            <div className="box-top">
              <div className="profile">
                <div className="profile-img">
                  <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                </div>
                <div className="name-user">
                  <strong>Liam mendes</strong>
                  <span>@liammendes</span>
                </div>
              </div>
            </div>
            <div className="client-comment">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Exercitationem, quaerat quis? Provident temporibus architecto
                asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam
                tenetur voluptates incidunt blanditiis sed atque cumque.
              </p>
            </div>
            <div className="reviews">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="far fa-star"></i>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Club;
