import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
function Termination() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area title-area-research">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Termination Policy</h2>
            <div className="container">
              <div className="title-item">
                <h2>Termination Policy</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>Termination Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <h2>
                Company may terminate the agreement with the distributor on
                ground of any reason which shall not be limited to the
                following:
              </h2>
              <ol>
                <li>
                  For reasons of non-performance with the business activities of
                  the company. If the Up-line turns out to be non-performer for
                  a period of 20 days with 0 (Zero) income, then the distributor
                  can join other Up-line. (Terms and conditions apply).{" "}
                </li>
                <li>
                  For any unethical or prejudicial work to the interest of the
                  company. For eg. If the distributor has already been assigned
                  a Distributor ID (on basis of Pan Card) and the distributor
                  tries to join any other Cross Line with other Sponsor (on
                  basis of the same Pan Card), then his attempt to create a new
                  ID shall be considered unethical and shall be blocked
                  permanently.
                </li>
                <li>
                  On non-compliance with the provisions of the agreement,
                  declaration, etc.. and discipline of the Company.
                </li>
                <li>
                  For breach of any term and condition of the agreement and
                  marketing plans. For eg. Swiping to Cross Line and Power Leg
                  is considered to be a breach of marketing plans.
                </li>
                <li>
                  In case information given is found to be
                  misleading/wrong/false.
                </li>
                <li>
                  If he/she is found to be convicted on any offence punishable
                  under law for the time being in force.
                </li>
                <li>If he/she is declared bankrupt/insolvent.</li>
                <li>If he/she migrates to other country.</li>
                <li>
                  Where company deems it necessary to terminate him/her, in the
                  interest of other distributors connected with his/her
                  group/team.
                </li>
              </ol>
              <h2>Termination of the distributor means termination of:</h2>
              <ol type="a">
                <li>
                  All rights and entitlements as distributor/ DS of the Company.
                </li>
                <li>Identification as a distributor/ DS of the Company.</li>
                <li>
                  Right to go to any Company’s office and attend Company’s
                  meetings/seminars. All Company’s trademarks, names, photos,
                  literatures, customer data, etc shall remain the property of
                  the Company . within 10 days of the termination he/she shall
                  return all articles and informations that may have handed over
                  to him by the Company. He/she shall cease to use things
                  entrusted to him/her by the Company.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Termination;
