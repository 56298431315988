import React, { useMemo, useState } from "react";

import useApiFunction from "../Shared/useApiFunction";
import Modal from "../Shared/Modal";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
function PriceDetailPayment({
  setTabIndex,
  verify,
  setVerify,
  quantity,
  previousData,
}) {
  const [data, setData] = useState({});
  const { t } = useTranslation(["PriceDetailPayment"]);
  const [verifyPh, setVerifyPh] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const { fetchFunc } = useApiFunction();
  const change = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  };
  const Toggle = () => setToggle((prv) => !prv);
  const fetchOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (data?.phone && data?.email) {
      const body = {
        phone: data?.phone,
        email: data?.email,
      };
      const res = await fetchFunc("send_otp", "POST", body);

      if (res.status) {
        toast.success(res.msg, { autoClose: 2000 });

        if (res.msg.verify === 0) {
          setVerify(res.msg);
          Toggle();
        } else {
          setVerifyPh(true);
        }
      } else {
        toast.warning(res.msg, { autoClose: 2000 });
      }
    } else {
      toast.warning("Please fill your phone number and email!!!", {
        autoClose: 2000,
      });
    }
    setIsLoading(false);
  };
  const fetch = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      data?.name &&
      data?.phone &&
      data?.pincode &&
      data?.address &&
      data?.city &&
      data?.state &&
      verifyPh
    ) {
      const txnid = new Date().getTime();
      const body = {
        merchant_id: "Tx" + txnid + "h",
        amount: quantity * 2000,
        phone: data.phone,
        email: data.email,
        productinfo: "Tirrent Booster",
        name: data.name,

        pinCode: data.pincode,
        address: data.address,
        city: data.city,
        state: data.state,
        town: data?.town,
      };
      if (previousData) {
        body["referral_code"] = `${previousData}`;
      }

      const res = await fetchFunc("payment", "POST", body);

      if (res.status) {
        setVerify(res.msg);
        setTabIndex("2");
      }
    } else if (!verifyPh) {
      toast.warning("Please Verify your phone number first!!!", {
        autoClose: 2000,
      });
    } else {
      toast.warning("Fill all the Details", { autoClose: 2000 });
    }
    setIsLoading(false);
  };

  const Adderess = useMemo(
    () => React.lazy(() => import("../Shared/PaymentAdderess")),
    []
  );

  return (
    <>
      <div className="about-content mb-2">
        <div className="adderess-card w-100">
          <div className="adderess-section">
            <div className="ticket-body">
              <h4 className="ticket-title">{t("priceDetails.title")}</h4>
              <div
                className="row contact-area"
                style={{ backgroundImage: "none" }}
              >
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>{t("priceDetails.name")}</span>
                  <div className="form-group">
                    <input
                      type="email"
                      id="name"
                      className="form-control"
                      onChange={(e) => change(e)}
                      value={data?.name}
                      required
                      placeholder={t("priceDetails.name")}
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>{t("priceDetails.email")}</span>
                  <div className="form-group d-flex">
                    {verifyPh ? (
                      <input
                        type="email"
                        value={data?.email}
                        className="form-control verify-mail"
                        placeholder={t("priceDetails.email")}
                      />
                    ) : (
                      <input
                        type="email"
                        id="email"
                        onChange={(e) => change(e)}
                        className="form-control verify-mail"
                        required
                        placeholder={t("priceDetails.email")}
                      />
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>{t("priceDetails.phone")}</span>
                  <div className="form-group d-flex">
                    {verifyPh ? (
                      <>
                        <input
                          type="number"
                          className="form-control verify-mail"
                          placeholder={t("priceDetails.phone")}
                          value={data?.phone}
                          readOnly
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="number"
                          id="phone"
                          onChange={(e) => change(e)}
                          className="form-control verify-mail"
                          required
                          placeholder={t("priceDetails.phone")}
                        />

                        <button
                          className="verify-btn"
                          onClick={(e) => fetchOtp(e)}
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && <i className="fa fa-refresh fa-spin" />}
                          {t("priceDetails.verify")}
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>{t("priceDetails.address")}</span>
                  <div className="form-group">
                    <input
                      type="text"
                      id="address"
                      onChange={(e) => change(e)}
                      className="form-control"
                      required=""
                      placeholder={t("priceDetails.address")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 mb-3">
                  <span>{t("priceDetails.town")}</span>
                  <div className="form-group">
                    <input
                      type="text"
                      id="town"
                      onChange={(e) => change(e)}
                      value={data?.town}
                      className="form-control"
                      required=""
                      placeholder={t("priceDetails.area")}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 mb-3">
                  <span>{t("priceDetails.pincode")}</span>

                  <div className="form-group">
                    <input
                      type="number"
                      id="pincode"
                      onChange={(e) => change(e)}
                      value={data?.pincode}
                      className="form-control"
                      required=""
                      placeholder={t("priceDetails.pincode")}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 mb-3">
                  <span>{t("priceDetails.city")}</span>
                  <div className="form-group">
                    <input
                      type="text"
                      id="city"
                      onChange={(e) => change(e)}
                      value={data?.city}
                      className="form-control"
                      required=""
                      placeholder={t("priceDetails.city")}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 mb-3">
                  <span>{t("priceDetails.state")}</span>
                  <div className="form-group">
                    <input
                      type="text"
                      id="state"
                      onChange={(e) => change(e)}
                      value={data?.state}
                      className="form-control"
                      required=""
                      placeholder={t("priceDetails.state")}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center ">
                <button
                  className="button-animation mb-3 mt-3"
                  style={{ border: "none" }}
                  type="submit"
                  onClick={(e) => fetch(e)}
                  disabled={isLoading}
                >
                  {isLoading && <i className="fa fa-refresh fa-spin" />}
                  {t("priceDetails.proceed")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggle && (
        <Modal show={toggle} close={Toggle}>
          <Adderess
            close={Toggle}
            setTabIndex={setTabIndex}
            verify={verify}
            setVerifyPh={setVerifyPh}
          />
        </Modal>
      )}
      <ToastContainer />
    </>
  );
}

export default PriceDetailPayment;
