import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import UserInfoPayment from "./UserInfoPayment";
import PriceDetailPayment from "./PriceDetailPayment";
import AdderessPayment from "./AdderessPayment";

function PaymentPage() {
  const [tabIndex, setTabIndex] = useState("3");

  const [verify, setVerify] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const quantity = location?.state?.quentity;
  const previousData = location?.state?.previousData;

  const { pathname } = useLocation();
  let token = "";

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  });

  return (
    <>
      <Header />
      <section className="about-area mb-5">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-8">
              <div className="section-title">
                <span
                  className={
                    tabIndex === "3"
                      ? "sub-title payment-menus selected"
                      : "sub-title payment-menus"
                  }
                >
                  Delivery Address
                </span>
                <span
                  className={
                    tabIndex === "2"
                      ? "sub-title payment-menus selected"
                      : "sub-title payment-menus"
                  }
                >
                  Choose Payment Mode
                </span>
              </div>

              {tabIndex === "3" && (
                <PriceDetailPayment
                  setTabIndex={setTabIndex}
                  verify={verify}
                  setVerify={setVerify}
                  quantity={quantity}
                  previousData={previousData}
                />
              )}
              {tabIndex === "2" && (
                <AdderessPayment
                  setTabIndex={setTabIndex}
                  verify={verify}
                  setVerify={setVerify}
                />
              )}
            </div>

            <div className="col-lg-4 research-development">
              {tabIndex == "2" ? (
                <div
                  className="mt-5"
                  style={{ border: "2px solid rgb(86, 89, 89)" }}
                >
                  <div className="p-4">
                    <h6>Price Details</h6>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p>Total MRP</p>
                      <h6>₹ {quantity * 3999}</h6>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p>Discount on MRP</p>
                      <h6>₹ -{quantity * 1999}</h6>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p>Convenience Fee</p>
                      <h6>Free</h6>
                    </div>
                    <hr />
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p>Total Amount</p>
                      <h6>₹ {quantity * 2000}</h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="about-img-wrap">
                  <div className="about-img-slider owl-theme">
                    <div className="about-img-item">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/barrel2.png"
                        alt="About"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PaymentPage;
