import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs5() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog5"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog5.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog5.heading")}</h2>
                  <p>{t("blog5.p1")}</p>
                  <p>{t("blog5.p2")}</p>

                  <p>{t("blog5.p3")}</p>
                  <p>{t("blog5.p4")}</p>
                  <p>{t("blog5.p5")}</p>
                  <p>{t("blog5.p6")}</p>

                  <ul className="blog1-point">
                    <li>{t("blog5.point1.heading")}</li>

                    <p>{t("blog5.point1.p1")}</p>

                    <p>{t("blog5.point1.p2")}</p>

                    <p>{t("blog5.point1.p3")}</p>
                    <p>{t("blog5.point1.p4")}</p>
                    <li>{t("blog5.point2.heading")}</li>
                    <p>{t("blog5.point2.p1")}</p>
                    <p>{t("blog5.point2.p2")}</p>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs5;
