import { useRef } from "react";
import { createPortal } from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Background = {
  width: "100%",
  height: "100%",
  left: "0",
  top: "0",
  position: "fixed",
  backgroundColor: "rgba(0,0,0,0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "10000",
};

const ModalWrapper = {
  boxShadow: "0 5px 16px rgba(0, 0, 0, 0.2)",
  color: "#000",
  position: "relative",
  zIndex: "10",
  width: "80%",
  display: "flex",
  justifyContent: "center",
  borderRadius: "10px",
};

const Modal = ({ show, close, children }) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      (children.type == "img" ||
        children._source.fileName.split("/").slice(-1)[0] ==
          "GalleryEvents.jsx")
    ) {
      close();
    }
  };

  return createPortal(
    <>
      <HelmetProvider>
        <Helmet>
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/bootstrap.min.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/custom.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/meanmenu.css"
          />
          {/* <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/boxicons.min.css"
          /> */}
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/fonts/flaticon.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/owl.carousel.min.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/owl.theme.default.min.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/odometer.min.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/magnific-popup.min.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/style.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/dark.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/responsive.css"
          />
          <link
            rel="stylesheet"
            href="/SiteTemplate/TirrentGlobal/assets/css/themechange.css"
          />
        </Helmet>
        {show ? (
          <div className="ljlk" onClick={closeModal} style={Background}>
            <div style={ModalWrapper} ref={modalRef}>
              {children}
            </div>
          </div>
        ) : null}
      </HelmetProvider>
    </>,

    document.getElementById("modal")
  );
};

export default Modal;
