import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs1() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs1"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            {/* <h2 className="policy">{t("blog1.title")}</h2> */}
            <div className="container">
              <div className="title-item">
                <h2>{t("blog1.title")}</h2>
                {/* <ul>
                  <li>
                    <Link to="/">{t("blog1.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog1.title")}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog1.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog1.heading")}</h2>
                  <p>{t("blog1.line1")}</p>
                  <p>{t("blog1.line2")}</p>

                  <p>{t("blog1.line3")}</p>
                  <p>{t("blog1.line4")}</p>

                  <ol className="blog1-point">
                    <li>{t("points.point1")}</li>

                    <p>{t("points.line1")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/exhaust-smoke.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("points.point2")}</li>

                    <p>{t("points.line2")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/reduce-fule.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point3")}</li>

                    <p>{t("points.line3")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/vehicle-noise .png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point4")}</li>

                    <p>{t("points.line4")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/overheating.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point5")}</li>

                    <p>{t("points.line5")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/gera-box.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point6")}</li>

                    <p>{t("points.line6")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/rough-idle.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point7")}</li>

                    <p>{t("points.line7")}</p>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "40px 0px",
                      }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/winterStart.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point8")}</li>

                    <p>{t("points.line8")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/engine.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("points.point9")}</li>

                    <p>{t("points.line9")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/combustion.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <h3 style={{ color: "#F89F31", fontWeight: "600" }}>
                      {t("points.point10")}
                    </h3>
                    <p>{t("points.line10")}</p>
                  </ol>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs1;
