import "../View/Loadingspinner.css";
function LoadingSpinner() {
  return (
    <div id="ct-loadding" className="ct-loader style14">
      <div className="ct-gears">
        <img
          className="big"
          src="https://demo.casethemes.net/industo/wp-content/themes/industo/template-parts/svgs/big.png"
          alt="Industry"
        />
        <img
          className="small"
          src="https://demo.casethemes.net/industo/wp-content/themes/industo/template-parts/svgs/small.png"
          alt="Industry"
        />
      </div>
    </div>
  );
}

export default LoadingSpinner;
