import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs11() {
  const { t } = useTranslation(["Blogs11"]);

  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog11.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
        <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog11.img"
                    )}`}
                    width="100%"
                  />

                  <h2>{t("blog11.heading")}</h2>
                  <p>{t("blog11.para")}</p>
                  <ul className="blog6-point">
                    <li> {t("blog11.point1")}</li>
                    <p>{t("blog11.para1")}</p>

                    <li> {t("blog11.point2")}</li>
                    <p>{t("blog11.para2")}</p>

                    <li> {t("blog11.point3")}</li>
                    <p>{t("blog11.para3")}</p>

                    <li> {t("blog11.point4")}</li>
                    <p>{t("blog11.para4")}</p>
                  </ul>

                  <p>{t("blog11.para5")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs11;
