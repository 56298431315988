import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs6() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog6"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog6.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
        <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog6.heading")}</h2>
                  <p>{t("blog6.p1")}</p>
                  <ul>
                    <li>{t("blog6.poin1")}</li>
                    <li>{t("blog6.poin2")}</li>
                    <li>{t("blog6.poin3")}</li>
                    <li>{t("blog6.poin4")}</li>
                  </ul>
                  <p>{t("blog6.p2")}</p>

                  <p>{t("blog6.p3")}</p>
                  <p>{t("blog6.p4")}</p>
                  <h4 className="blog6-subheading">{t("blog6.ques")}</h4>
                  <p>{t("blog6.para1")}</p>
                  <ol className="blog6-point">
                    <li> {t("blog6.1.head")}</li>

                    <p>{t("blog6.1.p1")}</p>
                    <ul className="subs-headings">
                      <li> {t("blog6.1.poin1")}</li>
                      <li> {t("blog6.1.poin2")}</li>
                      <li> {t("blog6.1.poin3")}</li>
                    </ul>
                    <p>{t("blog6.1.p2")}</p>
                    <li>{t("blog6.2.head")}</li>

                    <p>{t("blog6.2.p1")}</p>

                    <ul className="subs-headings">
                      <li>{t("blog6.2.poin1")}</li>
                      <li>{t("blog6.2.poin2")}</li>
                      <li>{t("blog6.2.poin3")}</li>
                    </ul>
                    <p></p>

                    <li>{t("blog6.3.head")}</li>

                    <p>{t("blog6.3.p")}</p>

                    <ul className="subs-headings">
                      <li>{t("blog6.3.poin1")}</li>
                    </ul>

                    <h4 className="blog6-subheading">{t("blog6.head")}</h4>
                    <p>{t("blog6.para2")}</p>
                  </ol>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs6;
