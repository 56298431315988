import Aos from "aos";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";

function PolicyGuidelines() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();

    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area privacy-policy">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">POLICY AND GUIDELINES</h2>
            <div className="container">
              <div className="title-item">
                <h2>POLICY AND GUIDELINES</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>POLICY AND GUIDELINES</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <h4>1. Tirrent Global Seller Role:</h4>
              <ol type="a">
                <li>
                  Every Direct Seller will follow the Policies and Procedure
                  Rulebook in respect of content of all meetings and events.
                </li>
                <li>
                  No Direct Seller, as conditions to provided assistance to a
                  prospective direct seller, in the development of their Tirrent
                  Global distributorship, ask the prospective direct seller to
                  pay any joining fee or purchase any specified number of
                  products.
                </li>
                <li>
                  Every Direct Seller must speak about the efforts required to
                  build an Tirrent Global business, and that commissions are
                  earned from the sale of products from within their team, and
                  not suggest that earnings may come from just joining Tirrent
                  Global, or from recruiting others to join Tirrent Global.
                </li>
                <li>
                  Direct Seller shall not engage in any high-pressure selling
                  but shall make a fair presentation of Tirrent Global Products
                  and the Tirrent Global Compensation Plan including, when and
                  where appropriate, demonstrations of such products.
                </li>
                <li>
                  Every Direct Seller should pass correct and specific
                  information which has been laid down in Tirrent Global
                  Compensation Plan and Tirrent Global Policies & Procedures
                  Rulebook.
                </li>
                <li>
                  Direct Seller must not promote the use of false, misleading,
                  inaccurate, or deceptive statements with prospective direct
                  sellers, preferred customers or other members of the
                  organisation.
                </li>
                <li>
                  When describing sponsoring, proper emphasis must be placed on
                  the importance of a prior or existing personal contact.
                </li>
                <li>
                  Every Direct Seller shall avoid all discourteous, deceptive,
                  misleading, unethical or immoral conduct or practices or
                  negative propaganda or adverse publicity.
                </li>
              </ol>
              <h4>2. Correct Representation of Tirrent Global Business:</h4>
              <ol type="a">
                <li>
                  Tirrent Global business generates revenue depending on the
                  effort put in by an individual. So, the Tirrent Global
                  business must be presented as an opportunity to gain income
                  through the retailing of products and guiding and mentoring of
                  others to do the same.
                </li>
                <li>
                  Lifestyle representations may be used if they provide
                  realistic, not exaggerated, income potentials, contain the
                  proper additional disclosures, and reflect the actual
                  lifestyle of the Tirrent Global direct seller having earned
                  revenue of Rs. 50 lacs and above.
                </li>
                <li>
                  It should be highlighted to prospective direct sellers that
                  there is a fixed fees to be paid for joining this business.
                </li>
                <li>
                  Direct Sellers should never promote the Tirrent Global
                  business opportunity by demeaning any other opportunity
                  existing in the market.
                </li>
                <li>
                  Disparaging statements regarding Tirrent Global LLC any of
                  their affiliates, employees, or its Direct Sellers are not
                  permitted.
                </li>
                <li>
                  Terms like “retirement”, phrases like “never work again”,
                  “always on vacation”, “always travelling,” “freedom from
                  work”, “free” etc., may lead to misrepresentation of the
                  Tirrent Global Business or Compensation Plan.
                </li>
              </ol>
              <h4>
                2.1 Use of Language is not allowed which states or indicates
                that
              </h4>
              <ol type="I">
                <li>
                  Success or profit in Tirrent Global Business is guaranteed or
                  assured.
                </li>
                <li>Business income is guaranteed.</li>
                <li>Risk free Business/ Permanent income.</li>
                <li>It is a Savings plan.</li>
                <li>
                  Language that states guarantee of success is not allowed.
                </li>
                <li>Financial or investment seminar.</li>
              </ol>
              <h4>3 Correct Income Representation:</h4>
              <ol type="a">
                <li>
                  All income representations must be limited to income from the
                  Tirrent Global business opportunity and provide realistic, not
                  exaggerated, income potentials. Direct Sellers should provide
                  truthful, clear and accurate pictures about the income that
                  may be earned as described in Tirrent Global Compensation
                  Plan.
                </li>
                <li>
                  Any quoted income figures should only be those officially
                  declared by Tirrent Global.
                </li>
                <li>
                  All income representations must provide realistic, not
                  exaggerated, income potentials and lifestyle expectations. It
                  must be clear that what is being described is income generated
                  from the Tirrent Global Compensation Plan.
                </li>
              </ol>
              <h4>3.1 Not Permitted Content for Income Representations: </h4>
              <ol type="I">
                <li>
                  No representation that income is guaranteed or assured is
                  allowed.
                </li>
                <li>
                  Stating that earn bonuses solely from the act of sponsoring
                  others is not allowed.
                </li>
                <li>
                  Describing income as continuing without including continued
                  effort and/or meeting qualifications should not be delivered
                  as such.
                </li>
                <li>
                  The use of terms that should be avoided are: “residual
                  income”, “passive income”, “royalty income” or “royalties” as
                  these misrepresents Tirrent Global Compensation Plan. Such
                  terms may suggest that one can continue earning even without
                  putting in effort, which is not correct.
                </li>
                <li>
                  Stating or implying that the Tirrent Global Compensation Plan
                  and/or business is a method of tax reduction, tax relief, tax
                  shelter, residual, passive, or recurring income is not
                  allowed.
                </li>
                <li>
                  Stating or implying income or earnings may be inheritable
                  without stating the heirs must qualify and/or have continued
                  effort in the business is not permitted.
                </li>
              </ol>
              <h4>4. Describing Product of Company/ Claims Made:</h4>
              <ol type="a">
                <li>
                  No Direct Seller shall make any claim that the Company product
                  is intended to treat, cure any previous Damage. Such
                  statements can be perceived miracle and unsubstantiated claims
                  such as these are strictly against general Company policies
                  and shall also violate relevant laws of the country.
                </li>
                <li>
                  Claims for Tirrent Global products must use language
                  specifically approved by Tirrent Global and may not be
                  altered.
                </li>
                <li>
                  All claims being made with regards to the products should be
                  as per the published Tirrent Global Policies & Procedures and
                  should be consistent with approved wording in approved Tirrent
                  Global literature only.
                </li>
                <li>
                  Any claims, statements, statistics or any other information
                  given by Direct Seller should be correct and substantiated.
                </li>
                <li>
                  Graphs, visuals, quotes and references to statistical data
                  must be substantiated with an information source and date.
                </li>
                <li>
                  Any unsubstantiated or wrong claims made may lead to loss of
                  credibility and/or may lead to disciplinary action.
                </li>
              </ol>
              <h4>5. Tirrent Global Business Environment</h4>
              <ol type="1">
                <li>
                  Promoting religious doctrine, political causes, or other
                  issues of a personal nature in the Tirrent Global business
                  environment is not permitted unless tied to building the
                  business.
                </li>
                <li>
                  The Tirrent Global business must be presented as an equal
                  opportunity business. Here are three areas that all direct
                  sellers need to be aware of:
                </li>
              </ol>
              <h4>5.1 Spiritual/Religious Communications</h4>
              <p>
                Spiritual references are not allowed as the main message or
                focus but can act as an element to support the main business
                message. As a presenter following things are not permitted to
                state:
              </p>
              <ol type="I">
                <li>
                  Demeaning or disparaging remarks about any religion or system
                  of belief is not allowed.
                </li>
                <li>
                  Not use the stage as a platform to promote religious and/or
                  personal social beliefs.
                </li>
                <li>
                  Not advocate that success is dependent on holding certain
                  beliefs.
                </li>
              </ol>
              <h4>5.2 Moral/Social Communications -</h4>
              <ol type="I">
                <li>
                  Positive statements related to values such as trustworthiness,
                  honesty, integrity, responsibility, commitment, family,
                  individual initiative, etc. are permitted.
                </li>
                <li>
                  Using the business platform to express personal beliefs that
                  are derogatory (i.e. against education or traditional
                  employment) is not allowed.
                </li>
                <li>
                  Encouraging an Tirrent Global Direct Seller to limit contact
                  or cut ties to Family and friends is not allowed.
                </li>
                <li>
                  Demeaning statements regarding personal lifestyle, ethnicity,
                  or the roles of males and/or females are not permitted.
                </li>
                <li>
                  Using the stage as a platform to promote personal social
                  beliefs or social and cultural issues are not permitted.
                </li>
              </ol>
              <h4>5.3 Political Communications —</h4>
              <ol type="I">
                <li>
                  Endorsement or denouncement of specific candidates, political
                  parties, and/or issues, unless specifically related to the
                  operation of independent Tirrent Global business is not
                  allowed.
                </li>
                <li>
                  Inflammatory labels or personal attacks on the character or
                  integrity of government officials or candidates are not
                  allowed.
                </li>
                <li>
                  Presenters may not use the stage as a platform to promote
                  their political beliefs and affiliation.
                </li>
                <li>
                  Presenters may not make references to preferences regarding
                  specific political views, parties, candidates or elected
                  officials
                </li>
              </ol>
              <h4>6. Confidential Information:</h4>
              <ol type="a">
                <li>
                  Direct Seller agrees that he/she will not disclose information
                  shared by company or other direct sellers to any third party
                  directly or indirectly, nor use the information to compete
                  with the Company directly or indirectly during or after the
                  term of the Tirrent Global Direct Seller Agreement.
                </li>
                <li>
                  Direct Seller should never disclose confidential information,
                  especially on social media or in presentations or personal
                  conversations.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PolicyGuidelines;
