import React, { useEffect } from "react";
import AOS from "aos";
import { Link, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useTranslation } from "react-i18next";
function Blogs3() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs3"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("blog3.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10  main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog3.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog3.heading1")}</h2>
                  <p>{t("blog3.line1")}</p>
                  <p>{t("blog3.line2")}</p>

                  <p>{t("blog3.line3")}</p>
                  <p>{t("blog3.line4")}</p>
                  <p>{t("blog3.line5")}</p>

                  <ol className="blog1-point">
                    <h3> {t("point.title")}</h3>
                    <p>{t("point.line")}</p>
                    <li>{t("point.point1")}</li>

                    <p>{t("point.line1")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/improving-lub.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point2")}</li>

                    <p>{t("point.line2")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/less-friction.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point3")}</li>

                    <p>{t("point.line3")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/engine-heat.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("point.point4")}</li>

                    <p>{t("point.line4")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/loadBalancing.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point5")}</li>

                    <p>{t("point.line5")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/cleaning.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point6")}</li>

                    <p>{t("point.line6")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/oxidation.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("point.point7")}</li>

                    <p>{t("point.line7")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/vibration.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                  </ol>
                  <ol className="blog1-point">
                    <h3>{t("char.title")}</h3>
                    <p>{t("char.titleline1")}</p>
                    <p>{t("char.titleline2")}</p>
                    <p>{t("char.titleline3")}</p>
                    <li> {t("char.point1")}</li>
                    <p>{t("char.line1")}</p>
                    <li>{t("char.point2")}</li>
                    <p>{t("char.line2")}</p>
                    <li>{t("char.point3")}</li>
                    <p>{t("char.line3")}</p>
                    <li>{t("char.point4")}</li>
                    <p>{t("char.line4")}</p>
                    <li>{t("char.point5")}</li>
                    <p>{t("char.line5")}</p>
                    <h3 style={{ color: "#F89F31", fontWeight: "600" }}>
                      {t("char.conclusion")}
                    </h3>
                    <p> {t("char.line6")}</p>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs3;
