import Aos from "aos";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";

function SellerAgreement() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area privacy-policy">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">DIRECT SELLER AGREEMENT</h2>
            <div className="container">
              <div className="title-item">
                <h2>DIRECT SELLER AGREEMENT</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>DIRECT SELLER AGREEMENT</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <p>
                This Direct Seller Agreement ("Agreement") is entered into on
                the day when an individual registers itself with Tirrent Global
                LLC company and purchase the franchise as per the agreement. It
                is entered between Tirrent Global LLC and an individual. <br />
                The person buying product, making a certain payment and
                completing the online KYC process to get “Direct seller
                registration ID” (hereinafter referred to as “Franchise Owner”).
              </p>
              <p>
                Distribution-ship holder is a person who is associated with
                Tirrent Global as an agent for stock keeper and product provider
                to the Direct Seller of Tirrent Global. His/ her work is to
                maintain a minimum pieces of product and sell them on its own
                and supply it when a user or Direct seller shows a payment
                invoice the company.
                <br />
                For Distribution-ship a person has to pay a fixed amount and
                lease an agency of Tirrent Global for a fixed period of time.
                He/ she will be provided and guided with product knowledge, a
                Demo Machine, information pamphlets, a system to maintain
                transaction, and a minimum number of Tirrent Global product.
                <br />
                When the agreement comes to end he/she can renew the agreement
                or can return the above provided things to Tirrent Global is as
                it is condition and the company will return his/ her paid amount
                which he / she paid while making the agreement.
              </p>
              <h4>Law:</h4>
              <ol>
                <li>
                  The Direct Seller has approached Tirrent Global in order to be
                  appointed on a principal-to-principal basis as a direct
                  seller.
                </li>
                <li>
                  Tirrent Global has agreed to appoint the Direct Seller in
                  accordance with the terms and conditions set out on Tirrent
                  Global web based KYC and Web based DSA (Direct Seller
                  Agreement).
                  <br />
                  Under the provision of the Information Technology Act, 2000,
                  web based Direct Seller Application Form, and this Agreement
                  as an electronic contract, are valid and enforceable and are
                  not required to be signed by either party physically.
                </li>
                <li>
                  NOW THEREFORE, in consideration of the foregoing, and the
                  premises and mutual agreements and covenants contained in this
                  Agreement, the Parties hereby agree as follows:
                </li>
              </ol>
              <h4>1. DEFINITIONS AND INTERPRETATION</h4>

              <ol type="a">
                <li>
                  In this Agreement, the following terms and expressions shall,
                  unless the context otherwise requires, have the meanings set
                  out as follows:
                  <ol type="1">
                    <li className="">
                      "Agreement" shall mean this Direct Seller Agreement, which
                      shall include:
                      <ul>
                        <li>The Distributor Policy and Procedures Rulebook,</li>
                        <li>SpeakerGuidelines and Terms conditions</li>
                      </ul>
                    </li>
                    <li>
                      ”Applicable Law" means any statute, law, regulation,
                      ordinance, rule, judgment, notification, order, decree,
                      bye- law, permits, licenses, approvals, consents,
                      authorisations, government approvals, directives,
                      guidelines, requirements, or other governmental
                      restrictions, or any similar form of a decision of, or
                      determination by, or any interpretation, policy or
                      administration, having the force of the law of any of the
                      foregoing, by any authority having jurisdiction over the
                      matter in question, whether in effect as of the date of
                      this Agreement or thereafter;
                      <ul>
                        <li>
                          "Confidential Information" means all non-public
                          information disclosed by Tirrent Global, its
                          Distributors or their agents in connection with this
                          Agreement (such entities collectively, the "Disclosing
                          Party") to the Direct Seller and his/her agents
                          (collectively, the "Receiving Party") that is
                          designated as confidential or that, given the nature
                          of the information or the circumstances surrounding
                          its disclosure, reasonably should be considered as
                          confidential.
                        </li>
                        <li>
                          Confidential Information includes, without limitation
                          <ol type="i">
                            <li>
                              Non-public information relating to the Disclosing
                              Party’s technology, products, services, processes,
                              data, customers, business plans and methods,
                              promotional and marketing activities, finances and
                              other business affairs,
                            </li>
                            <li>
                               Third-party information that the Disclosing Party
                              is obligated to keep confidential
                            </li>
                            <li>
                              The nature, content and existence of a
                              relationship, discussions or negotiations between
                              the Parties and
                            </li>
                            <li>Customer data.</li>
                          </ol>
                        </li>
                        <li>
                          Confidential Information does not include, any
                          information that
                          <ol type="i">
                            <li>
                              Is or becomes publicly available without breach of
                              this Agreement (provided, however, information
                              that is rumoured or reported does not become
                              public based only on such rumours or reports),
                            </li>
                            <li>
                              Was known by the Receiving Party prior to its
                              receipt from the Disclosing Party,
                            </li>
                            <li>
                              Is disclosed to the Receiving Party from any third
                              party, except where the Receiving Party knows or
                              reasonably should know, that such disclosure
                              constitutes a wrongful or tortious act, or
                            </li>
                            <li>
                              Is independently developed by the Receiving Party
                              without the use of any Confidential Information;
                            </li>
                          </ol>
                        </li>
                      </ul>
                    </li>
                    <li>
                      "Cooling”
                      <p>
                        Set out on Tirrent Global web-based KYC and web based
                        DSA (Direct Seller Agreement) under the provision of the
                        Information Technology Act, 2000, an electronic contract
                        is valid and enforceable, in Direct Seller Off Period"
                        shall, in accordance with the terms and conditions.
                      </p>
                    </li>
                    <li>
                      Application Form mean a period of particular days,(three
                      days) from the date of execution of this Agreement, within
                      which period the Direct Seller shall be entitled to
                      terminate this Agreement without penalty and be entitled
                      to full refund of price for any unused and unpacked
                      Tirrent Global products purchased from Tirrent Global and
                      such product or material is returned in saleable,
                      marketable condition;
                    </li>
                    <li>
                      “Tirrent Global Products" means the products that may be
                      manufactured, imported, or sold by Tirrent Global from
                      time to time, more fully described and attached in
                      Annexure of this Agreement, which Annexure may be amended
                      by Tirrent Global at its sole discretion.
                    </li>
                    <li>
                      ”Party" means Tirrent Global or the Direct Seller
                      individually, and “Parties” means Tirrent Global and the
                      Direct Seller collectively;
                    </li>
                    <li>
                      ”Proprietary Right" means all trade secrets, patents and
                      patent applications, trademarks (whether registered or
                      unregistered and including any goodwill acquired in such
                      trademarks), service marks, trade names, business names,
                      internet domain names, e-mail address names, copyrights
                      (including rights in computer software), moral rights,
                      database rights, design rights, rights in know-how, rights
                      in confidential information, rights in inventions (whether
                      patentable or not) and all other intellectual property and
                      proprietary rights (whether registered or unregistered,
                      and any application for the foregoing), and all other
                      equivalent or similar rights that may subsist anywhere in
                      the world;
                    </li>
                    <li>
                      Interpretation. In construing this Agreement:
                      <ul>
                        <li>
                          Headings in this Agreement are inserted for ease of
                          reference only and do not form part of this Agreement
                          and shall have no effect on the interpretation of any
                          of the provisions hereof.
                        </li>
                        <li>
                          Reference to “this Agreement” in this Agreement means
                          this Agreement as it may be amended from time to time.
                        </li>
                        <li>
                          The words “hereof”, “herein” and “hereunder” and other
                          words of similar import used in this Agreement refer
                          to this Agreement as a whole and not to any particular
                          part of this Agreement.
                        </li>
                        <li>
                          Where the context so requires in this Agreement, words
                          importing the singular number shall include the plural
                          and vice versa.
                        </li>
                        <li>
                          References to a person include a body corporate and an
                          un-incorporated association of persons; and
                        </li>
                        <li>
                          References to Recitals, Clauses and Annexures are
                          references to Recitals, Clauses and Annexures of and
                          to this Agreement, and unless otherwise stated
                          references to this Agreement includes the Annexures
                          hereto.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>

              <h4>(2) APPOINTMENT OF DIRECT SELLER </h4>
              <ul>
                <li>
                  Tirrent Global hereby appoints, as on the Effective Date, on a
                  non- exclusive and revocable basis, the Direct Seller as a
                  direct seller for Tirrent Global Products throughout the
                  territory of India, and the Direct Seller hereby agrees to and
                  accepts such appointment on the terms and conditions set out
                  herein. Pursuant to the above-mentioned appointment, the
                  Direct Seller may, on a non-exclusive basis, purchase Tirrent
                  Global Products for the purpose of sale, distribution, and
                  marketing throughout the territory of India.
                </li>
              </ul>
              <h4>(3) OBLIGATIONS OF DIRECT SELLER</h4>
              <ol type="1">
                <li>
                  The Direct Seller shall at all times during the Term of this
                  Agreement comply with the following obligations and
                  restrictions, in addition to those contained elsewhere in this
                  Agreement:
                </li>
                <li>
                  Direct Seller shall not sell any Tirrent Global Products for a
                  price exceeding the Maximum Retail Price (MRP) mentioned on
                  the labels of the Tirrent Global Products;
                </li>
                <li>
                  The Company does not provide any warranty and/or guarantee
                  and/or refund and/or replacement and/or return of whatsoever
                  nature-of its products. In pursuance of the same, it shall be
                  the duty of the Direct Seller to compulsorily make known to
                  the prospective customers of the said policy of the company
                  with reference to warranty and/or guarantee and/or refund
                  and/or replacement and/or return while dealing with the
                  prospective customers.
                </li>
                <li>
                  The Company does not provide any insurance, instalment or
                  credit payment scheme for the products sold by the Company in
                  view of which Direct Seller cannot make any claims with
                  reference to above.
                </li>
                <li>
                  DirectSeller shall always carry his/her identity card issued
                  and shall not visit any consumer’s premises without prior
                  appointment / approval;
                </li>
                <li>
                  Direct seller shall truthfully and clearly identify
                  himself/herself and state the purpose of solicitation to any
                  prospective consumer and provide complete details of his/her
                  relationship with Tirrent Global;
                </li>
                <li>
                  Direct Seller shall provide complete explanation and
                  demonstration as well as description of the nature of the
                  Tirrent Global Products being offered for sale, provide
                  details of prices, terms of payment, return policies, terms of
                  guarantee, after-sales service, complaint redressal mechanism
                  etc. to all consumers;
                </li>
                <li>
                  Direct Seller shall not use misleading, deceptive, or unfair
                  sales practices, including misrepresentation of actual or
                  potential sales or earnings, in their interaction with
                  prospective or existing direct sellers;
                </li>
                <li>
                  Direct Seller shall not make any false or untrue claims
                  regarding the health benefits of Tirrent Global Products;
                </li>
                <li>
                  The products as offered by the Company helps for providing
                  health benefits to you vehicle, which may not be tangible
                  and/or apportioned and/or visibly seen. The prospective
                  customers shall not be in any way be instructed and/or advised
                  by the Direct Seller to alter and/or modify and/or change any
                  oil/service station/engine treatments as recommended to the
                  prospective customers by the concerned expert as may be hired
                  by the prospective customer.
                </li>
                <li>
                  Delivery of the goods ordered by the prospective customers
                  with the Company shall always be subjected to availability of
                  stock of the products.
                </li>
                <li>
                  Direct Seller shall not use misleading, deceptive or unfair
                  trade practices for sale or recruitment of prospective direct
                  sellers;
                </li>
                <li>
                  Direct Seller shall not require or encourage other Tirrent
                  Global direct sellers to purchase Tirrent Global Products in
                  unreasonably large quantities;
                </li>
                <li>
                  Direct Seller shall not provide any literature, marketing
                  and/or training material to a prospective and/or existing
                  direct seller which have not been approved by Tirrent Global
                  in writing;
                </li>
                <li>
                  Direct Seller shall not require any prospective or existing
                  direct sellers to purchase any literature or
                  marketing/training material or sales demonstration equipment;
                </li>
                <li>
                  Direct Seller shall not sell, promote or market Tirrent Global
                  Products on any e-commerce website, internet auction sites,
                  internet-based classified listings or any other similar
                  portals;
                </li>
                <li>
                  Direct Seller shall not export or sell directly or indirectly
                  outside the territory of India any Tirrent Global Products or
                  any literature, sales aids, promotional material regarding
                  Tirrent Global Products.
                </li>
                <li>
                  Direct Seller shall not make any factual representation to a
                  prospective customer that cannot be verified or make any
                  promise that cannot be fulfilled;
                </li>
                <li>
                  Direct Seller shall not present any advantages of direct
                  selling to any prospective direct seller in a false or
                  deceptive manner;
                </li>
                <li>
                  Direct Seller shall not make or cause, or permit to be made,
                  any representation relating to Tirrent Global’s business,
                  including remuneration system and agreement between, or
                  relating to Tirrent Global Products which is false or
                  misleading.
                </li>
                <li>
                  Direct seller shall not receive and/or demand any sale
                  consideration or any part of it pursuant to the sale of the
                  products of the Company in cash and/or in kind in his personal
                  and/or affiliates bank account. It shall be non-revocable
                  obligation of the Direct Seller to provide the bank details of
                  the Company for the transfer of sale consideration of the
                  products of the Company.
                </li>
                <li>
                  Direct seller shall not demand any token money/earnest money
                  from the prospective customers for the products ordered.
                </li>
                <li>
                  Direct Seller shall not use, or cause or permit to be used,
                  fraud, coercion, harassment, unconscionable or unlawful means
                  in promoting its direct selling practice,
                </li>
                <li>
                  Direct seller shall not disparage and/or defame the Company in
                  any manner in front of the prospective customers.
                </li>
                <li>
                  Direct seller shall necessarily communicate to the prospective
                  customers that they shall be solely responsible for the
                  products bought after inspection of the products of the
                  Company. Notwithstanding, in case, the goods tend to be
                  defective, prospective customers would always have the option
                  to not accept the products in its defective condition of which
                  loss shall be borne by the Direct Seller if the goods were not
                  defective during the inspection of the Direct Seller during
                  pick up from authorised centres of the Company.
                </li>
                <li>
                  Direct Seller shall comply with all Applicable Laws, in the
                  conduct of his/her business. The Direct Seller shall be
                  responsible for obtaining any applicable registration,
                  license, approval or authorisation, including tax
                  registrations, to carry on the business as a Direct Seller.
                  Copies of such registrations, licenses, approvals and
                  authorisations shall be provided to Tirrent Global upon
                  request;
                </li>
                <li>
                  Direct Seller shall maintain all requisite books, accounts and
                  records in compliance with Applicable Law;
                </li>
              </ol>
              <p>
                The Direct Seller agrees and acknowledges that the obligations
                and restrictions contained in this Clause 3 are not exhaustive,
                and are in addition to other obligations that may be imposed on
                the Direct Seller from time to time, including those contained
                in the Distributor Policy and Procedures Rulebook.
              </p>
              <h4>(4) ORDER CONFIRMATION & PROCESS THEREAFTER</h4>
              <p>
                The Direct seller, after the confirmation of order from the
                prospective customers, shall be required to do the following-
              </p>
              <ol type="1">
                <li>
                  Immediately contact the company & enquire about the
                  availability of the stock of the product ordered by the
                  prospective customers. In case of non-availability, the same
                  shall immediately be informed to the prospective customer
                  without any delay or demur.
                </li>
                <li>
                  In case of availability, procure the product from the pickup
                  centres authorised by the Company. During such procurement
                  from authorised pick-up centres, the Direct Seller shall
                  necessarily carry out thorough inspection of the products for
                  any defects of whatsoever nature. It shall be only upon
                  satisfaction of such inspection; the Direct Seller shall
                  accept the delivery of product from authorised pick-up centres
                  of the company.
                </li>
                <li>
                  After such procurement upon satisfaction by the Direct Seller,
                  he shall deliver the product to the prospective customers
                  through any mode as may be desirable to the Direct Seller.
                  Notwithstanding anything, the Company shall not be in any way
                  be responsible for any damage of whatsoever nature to the
                  products during the transit from the authorised pickup centres
                  of the Company. If any damage is caused to the products as
                  ordered by the prospective customers during transit, the
                  Direct Seller shall be solely responsible for the same & the
                  company shall not in any way be asked to
                  replace/repair/refurbish the products of the company. Any loss
                  caused during transit shall be borne by the Direct Seller.
                </li>
                <li>
                  Upon delivery to the prospective customer, theDirect Seller
                  shall necessarily ask the prospective customer and/or its
                  agents as may be specifically assigned by the prospective
                  customer to inspect the product delivered for any defects of
                  whatsoever nature. The Direct Seller shall further communicate
                  to the prospective customer, that after necessary inspection,
                  the prospective customer shall only purchase the product after
                  due satisfaction and if the said products as ordered are
                  accepted, the prospective customer shall be liable to waive
                  off any right of warranty and/or guarantee and/or refund
                  and/or replacement and/or return from the Company.
                </li>
                <li>
                  Upon acceptance of product, the Direct Seller shall provide
                  the Bank details of the Company for immediate IMPS/NEFT and/or
                  any other mode of instant transfer for the payment of
                  consideration towards the products sold by the Direct Seller.
                  It shall be responsibility of the DirectSeller to ensure that
                  the consideration of the products sold received by the Company
                  and only upon such receipt by the Company
                </li>
                <li>
                  The Direct Seller shall handover the possession and/or
                  ownership of the products to the prospective customer.
                </li>
              </ol>
              <h4>(5) OBLIGATIONS OF THE COMPANY.</h4>
              <ol type="1">
                <li>
                  The Company shall provide upon execution of this Agreement an
                  identity card to the Direct Seller which shall necessarily
                  contain the name, address of the Direct Seller, Identity of
                  the Company including address of the place of business of the
                  Company. The Company shall also provide a Brochure of the
                  business of theCompany to the Direct Seller for the purposes
                  of conducting business of the Company.
                </li>
                <li>
                  The Company shall provide all necessary, accurate and complete
                  information with respect to demonstration of the products of
                  the Company, its prices, terms of payment, return, exchange,
                  refund policy to the Direct Seller which can then be
                  communicated to prospective customers.
                </li>
              </ol>
              <h4>(6) PAYMENT TO THE DIRECT SELLER</h4>
              <ol type="1">
                <li>
                  The company shall pay to the Direct Seller a bonus on the
                  basis of its sales performance as made by Direct Seller after
                  every 12 hours.
                </li>
                <li>
                  Bonus paid to the Direct Seller depends on the company policy
                  and upon the type of products sold and also depends on the
                  price on which the said product has been sold by such Direct
                  Seller.
                </li>
                <li>
                  The company may change its policy for payment of the bonus
                  without prior notice to the Direct Seller and that the Direct
                  Seller hereby agrees to accept the same.
                </li>
              </ol>
              <h4>(7) TERM AND TERMINATION</h4>
              <p>
                This Agreement shall commence on the Effective Date and shall
                continue in force until terminated by either Party in accordance
                with the below mentioned provisions ("Term").
              </p>
              <ol type="1">
                <li>
                  Termination. Notwithstanding anything contained herein, this
                  Agreement may be terminated at any time:
                  <ol type="a">
                    <li>by the mutual consent of Parties;</li>
                    <li>
                      By Tirrent Global upon providing the Direct Seller not
                      less than seven days’ written notice;
                    </li>
                    <li>
                      By Tirrent Global, in the event of any breach or default
                      by the Direct Seller of any of the Direct Seller’s
                      obligations under this Agreement or under Applicable Law,
                      and upon the failure of the Direct Seller to cure or
                      rectify such breach or default within a period of 30
                      (thirty) days of receiving notice of the same;
                    </li>
                    <li>
                      by the Direct Seller upon providing Tirrent Global not
                      less than seven days’ notice
                    </li>
                    <li>
                      By the Direct Seller where he/she has made no sale of
                      Tirrent Global Products for a period of 6 (six) months
                      from the Effective Date, or since the date of the last
                      sale made by the Direct Seller.
                    </li>
                  </ol>
                  <li>
                    Company may terminate the agreement with the distributor on
                    ground of any reason which shall not be limited to the
                    following:
                    <ol type="a">
                      <li>
                        On non-compliance with the provisions of the agreement,
                        declaration, etc. and discipline of the Company.
                      </li>
                      <li>
                        For reasons of non-performance with the business
                        activities of the company. If the Up line turns out to
                        be non-performer for a period of ten days with 0 (Zero)
                        income, then the distributor can join other Up line.
                        (Terms and conditions apply).
                      </li>
                      <li>
                        For any unethical or prejudicial work to the interest of
                        the company. For e.g. If the distributor has already
                        been assigned a Distributor ID (on basis of Pan Card)
                        and the distributor tries to join any other Cross Line
                        with other Sponsor (on basis of the same Pan Card), then
                        his attempt to create a new ID shall be considered
                        unethical and shall be blocked permanently.
                      </li>
                      <li>
                        For breach of any term and condition of the agreement
                        and marketing plans. For e.g. Swiping to Cross Line and
                        Power Leg is considered to be a breach of marketing
                        plans.
                      </li>
                      <li>
                        In case information given is found to be
                        misleading/wrong/false.
                      </li>
                      <li>
                        If he/she is found to be convicted on any offence
                        punishable under law for the time being in force.
                      </li>
                      <li>If he/she is declared bankrupt/insolvent.</li>
                      <li>If he/she migrates to other country.</li>
                      <li>
                        Where company deem sit necessary to terminate him/her,
                        in the interest of other distributors connected with
                        his/her group/team.
                      </li>
                    </ol>
                    <p>
                      Termination of the distributor means termination of:
                      <ol type="i">
                        <li>
                          All rights and entitlements as distributor of the
                          Company.
                        </li>
                        <li>Identification as a distributor of the Company.</li>
                        <li>
                          Right to go to any Company’s office and attend
                          Company’s meetings/seminars.
                        </li>
                      </ol>
                    </p>
                    <p>
                      All Company’s trademarks, names, photos, literatures,
                      customer data, etc. shall remain the property of the
                      Company. Within 10 days of the termination he/she shall
                      return all articles and information that may have handed
                      over to him by the Company. He/she shall cease to use
                      things entrusted to him/her by the Company.
                    </p>
                  </li>
                  <li>
                    Consequences of Termination. Termination in accordance with
                    this Agreement shall not prejudice or affect any right or
                    action or remedy that has accrued or shall thereafter accrue
                    in relation to either Party. Termination or expiration of
                    this Agreement shall not affect any Fees or payments due
                    from the Direct Seller to Tirrent Global that are incurred
                    and become due prior to the date of termination, nor relieve
                    any Party from liability incurred prior to termination or
                    expiration. Upon termination, the Direct Seller will lose
                    all privileges, rights, bonuses or margins otherwise payable
                    to him/her as a direct seller.
                  </li>
                  <li>
                    Survival.Clauses7(Indemnification),4(Terms and Termination),
                    8 (Limitation of Liability), 9 (Confidentiality), 10
                    (Ownership Rights) and 13 (Miscellaneous) will survive the
                    termination of this Agreement.
                  </li>
                  <li>
                    Resignation Policy
                    <p>
                      Resignation letters shall be uploaded through Distributor
                      ID along with proper signatures and details. The cooling
                      period after resignation will be minimum 45 days to
                      maximum upto 6 months. The company reserves the right to
                      decide the maximum period for him/her.
                    </p>
                    <p>
                      During the cooling period the distributor shall not be
                      active under or with any other distributors associated
                      with the company. If he/she is found to breach the above
                      mentioned condition then he/she shall be committing an
                      unethical act and his/her ID will be blocked permanently
                      by the company. Couples are considered the same entity as
                      per the rules of the Company and thus if any one of them
                      resigns or is terminated the other will not be allowed to
                      continue his/her association with the Company.
                    </p>
                  </li>
                </li>
              </ol>
              <h4>(8) REPRESENTATIONS AND WARRANTIES</h4>
              <p>
                The Direct Seller hereby represents and warrants to Tirrent
                Global as follows:
              </p>
              <ol type="1">
                <li>
                  He/she has full power and authority to execute, deliver and
                  perform his/her obligations under this Agreement and to carry
                  out the transactions contemplated hereby;
                </li>
                <li>
                  He/she has taken all necessary actions under Applicable Law
                  for the execution, delivery and performance of this agreement;
                </li>
                <li>
                  This Agreement will constitute a legal, valid and binding
                  obligation enforceable against him/her in accordance with the
                  terms hereof; and
                </li>
                <li>He/she has attained the age of majority in India.</li>
              </ol>
              <h4>(9)INDEMNIFICATION</h4>
              <ol type="1">
                <li>
                  Indemnification. The Direct Seller (in this context, the
                  "Indemnifying Party") will defend, indemnify and hold harmless
                  the Tirrent Global and its affiliates, and each of their
                  directors, officers, employees and agents (collectively, the
                  "Indemnified Parties"), from and against any and all claims,
                  losses, liabilities, damages and expenses (including, without
                  limitation, reasonable attorneys' fees) arising from or
                  relating to any claim, action or proceeding (each a "Claim")
                  brought by any third party based upon:
                  <ol type="1">
                    <li>
                      any actual breach of representations, warranties or
                      covenants set forth in this Agreement;
                    </li>
                    <li>
                      any violation of this Agreement, Tirrent Global’s policies
                      and procedures, rules and regulations, Tirrent Global’s
                      marketing program manual or guidelines or any other
                      directive of Tirrent Global.
                    </li>
                    <li>
                      engaging in any conduct that is not authorised or that is
                      specifically prohibited by Tirrent Global under
                      thisAgreement;
                    </li>
                    <li>
                      any claim, demand or action initiated by a
                      customer/consumer of the Direct Seller, in respect of
                      which has no responsibility;
                    </li>
                    <li>
                      any actual violation of law, gross negligence, wilful
                      misconduct, or fraud.
                    </li>
                  </ol>
                </li>
                <li>
                  Indemnification Procedure. The Indemnified Parties will
                  cooperate with the Indemnifying Party in the defence of any
                  Claim. Any Indemnified Party may participate in the defence at
                  its own expense. If at any time, any Indemnified Party
                  reasonably determines that any Claim might adversely affect
                  any Indemnified Party, such Indemnified party may take control
                  of the defence of the Claim at such Indemnified Party’s
                  expense (without limiting the Indemnifying Party’s
                  indemnification obligations), and in such event such
                  Indemnified Party and its counsel will proceed diligently and
                  in good faith with such defence. Indemnifying Party will not
                  consent to the entry of any judgment or enter into any
                  settlement without the Indemnified Parties’ prior written
                  consent, which may not be unreasonably withheld.
                </li>
              </ol>
              <h4>(10) LIMITATION OF LIABILITY</h4>
              <p>
                Tirrent Global will not be liable to the Direct Seller for any
                incidental or consequential damages (including lost profits,
                lost data or loss of good will) arising out of this Agreement.
              </p>
              <h4>(11) CONFIDENTIALITY</h4>
              <ol type="1">
                <li>
                  Non-Disclosure. The Receiving Party shall
                  <ol type="a">
                    <li>
                      Hold all Confidential Information in confidence and shall
                      not disclose the Disclosing Party’s Confidential
                      Information to anyone, at any time, other than persons who
                      have a need to receive such Confidential Information to
                      comply with the terms of this Agreement; and
                    </li>
                    <li>
                      Protect the Disclosing Party’s Confidential Information
                      against unauthorised access, use, disclosure, destruction,
                      loss or alteration using the same degree of care that the
                      Disclosing Party uses to protect his/her own highly
                      sensitive and/or proprietary information, but in no event
                      less than a reasonable degree of care.
                    </li>
                    <li>
                      Restrictions on Use. The Receiving Party shall not use the
                      Disclosing Party’s Confidential Information for his/her
                      own benefit or for the benefit of third parties, or for
                      any purpose other than as necessary for the Receiving
                      Party under this Agreement.
                    </li>
                    <li>
                      Breach Notification. Upon becoming aware of any
                      unauthorised access to or disclosure, use, loss, damage or
                      destruction of any Confidential Information, the Receiving
                      Party shall promptly notify the Disclosing Party in
                      writing.
                    </li>
                    <li>
                      Return or Destruction. Within 10 (ten) days of an express
                      request or termination of this Agreement, the Receiving
                      Party shall return all Confidential Information received
                      under this Agreement, including all copies or partial
                      copies thereof in any form or format to the extent
                      technically feasible to do so. If it is not technically
                      feasible to return all information and the Disclosing
                      Party consents in writing, Receiving Party shall destroy
                      such information using such methods as are reasonably
                      acceptable to the Disclosing Party.
                    </li>
                    <li>
                      Terms of Agreement. The Direct Seller shall not disclose
                      the terms of this Agreement to any third parties without
                      the prior written consent of Tirrent Global, except that
                      Direct Seller may disclose its terms
                      <ol type="a">
                        <li>
                          in confidence, to his/her accountants, legal counsel,
                          tax advisors and other financial and legal advisors;
                          or
                        </li>
                        <li>
                          As required during the course of litigation.
                          <ul>
                            <li>
                              Compelled Disclosure. If the Direct Seller becomes
                              legally compelled (by Applicable Law or by
                              deposition, interrogatory, request for documents,
                              order, subpoena, civil investigative demand or
                              similar process issued by a court of competent
                              jurisdiction or by a government body) to disclose
                              any of Tirrent Global’s Confidential Information
                              or the terms of this Agreement, Direct Seller
                              shall provide Tirrent Global with prompt prior
                              written notice (to the extent legally permitted)
                              of any such requirement so that Tirrent Global may
                              seek a protective order or other appropriate
                              remedy, and the Direct Seller shall provide all
                              assistance reasonably necessary for Tirrent Global
                              to seek such order or remedy. In the event that
                              Tirrent Global does not obtain such protective
                              order or other remedy, then the Direct Seller may
                              disclose only that portion of the Confidential
                              Information or terms of this Agreement that is
                              legally required to disclose.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>(12) PROPRIETARY RIGHTS</h4>
              <ol type="1">
                <li>
                  Ownership. Parties agree that, except as expressly provided to
                  the contrary, this Agreement does not transfer ownership of
                  any Proprietary Rights in any Tirrent Global Materials.
                  Tirrent Global shall retain ownership of the Tirrent Global
                  Materials.
                </li>
                <li>
                  License Grant. Tirrent Global grants to the Direct Seller, on
                  a non- exclusive, revocable basis, limited license to use the
                  Tirrent Global Materials solely for the purpose of acting as a
                  direct seller in accordance with this Agreement.
                </li>
                <li>
                  Restrictions. Except to the extent authorised or permitted in
                  this Agreement or by law, Direct Seller shall not:
                  <ol type="a">
                    <li>
                      use theTirrent Global Materials in any manner without the
                      prior consent of Tirrent Global;
                    </li>
                    <li>
                      incorporate, bundle, integrate or link Tirrent Global
                      Materials into any documentation or materials used by the
                      Direct Seller;
                    </li>
                    <li>
                      copy, transfer or distribute the Tirrent Global Materials
                      (electronically or otherwise);
                    </li>
                    <li>
                      sublicense or assign the license for the Tirrent
                      GlobalMaterials.
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>(13)  INDEPENDENT CONTRACTOR</h4>
              <p>
                The Parties are and shall be independent contractors to one
                another, and nothing herein shall be deemed to cause this
                Agreement to create an agency or partnership between the
                Parties. Nothing in this Agreement shall be interpreted or
                construed as creating or establishing the relationship of
                employer and employee between Tirrent Global and the Direct
                Seller.
              </p>
              <h4>(14)  NO EXCLUSIVITY</h4>
              <p>
                The appointment of the Direct Seller under this Agreement is not
                exclusive and the Direct Seller acknowledges and agrees that
                Tirrent Global may appoint any other third party as a direct
                seller with respect to the same Tirrent Global Products for the
                same territory, without the consent of the Direct Seller.
              </p>
              <h4>(15) MISCELLANEOUS</h4>
              <ol type="1">
                <li>
                  Notices. Any notice or other communication under this
                  Agreement given by any Party to the other Party will be in
                  writing and, to be effective, must be delivered by registered
                  letter, receipted commercial courier, or electronically
                  receipted facsimile transmission (acknowledged in like manner
                  by the intended recipient) at the address specified below.
                  Either Party may from time to time change the addresses or
                  individuals specified in this Clause by giving the other Party
                  notice of such change in accordance with this Clause.
                  <ol type="a">
                    <li>
                      Notice to Tirrent Global: Name: Compliance Department
                    </li>
                    <li>
                      Notice to Direct Seller: As details provided in the
                      agreement, as per Tirrent Global record and KYC filed with
                      the company.
                    </li>
                  </ol>
                </li>
                <li>
                  Waiver; Remedies. A waiver of any breach or default under this
                  Agreement will not constitute a waiver of any other or
                  subsequent breach or default. The failure of either Party to
                  enforce any term of this Agreement will not constitute a
                  waiver of such Party’s rights to subsequently enforce the
                  term. The remedies specified in this Agreement are in addition
                  to any other remedies that may be available at law or in
                  equity.
                </li>
                <li>
                  Sever ability; Entire Agreement. If any term of this Agreement
                  is held to be invalid, such invalidity will not affect the
                  remaining terms. This Agreement, together with the attached
                  Annexure represents the entire agreement between the Parties
                  with respect to the subject matter hereof and supersedes any
                  previous or contemporaneous oral or written agreements
                  regarding such subject matter. This Agreement may be executed
                  by facsimile and in counterparts, which together will
                  constitute one and the same agreement.
                </li>
                <li>
                  Governing Law. This Agreement will be governed by the laws of
                  India. Subject to Clause 13.5 below, the Parties hereby
                  irrevocably consent to the exclusive jurisdiction of courts
                  located at Mumbai, India, with respect to any claims, suits or
                  proceedings arising out of or in connection with this
                  Agreement or the transactions contemplated hereby.
                </li>
                <li>
                  Dispute Resolution. All disputes and differences arising out
                  of or in connection with this Agreement, if not resolved
                  within 15 (fifteen) days through discussion between the
                  Parties, shall be referred to the arbitration of an arbitrator
                  appointed by a director of Tirrent Global. The decision and
                  award determined by such arbitration will be final and binding
                  upon the Parties. The Arbitration shall be conducted in
                  accordance with the provisions of the Arbitration and
                  Conciliation Act, 1996, as may be in force from time to time.
                  The arbitration proceedings shall be conducted in English and
                  the venue of the arbitration shall be Mumbai. The arbitrators
                  shall pass a reasoned award in writing within 4 (four) months
                  of the date of the appointment of the Presiding Arbitrator.
                </li>
                <li>
                  Force Major. Neither Party shall be in breach of this
                  Agreement nor liable for delay in performing, or failure to
                  perform, any of its obligations (other than payment) under
                  this Agreement if such delay or failure result from events,
                  circumstances or causes beyond its reasonable control. In such
                  circumstances, the time for performance shall be extended by a
                  period equivalent to the period during which performance of
                  the obligation has been delayed or failed to be performed. If
                  the period of delay or non-performance continues for 3 (three)
                  months, the Party not affected may terminate this Agreement by
                  giving 45 (forty-five) days' written notice to the affected
                  Party, and the effects of termination described in Clause 5.2
                  shall become applicable
                </li>
              </ol>
              <p>
                This web based agreement is executed by each Party, the “Direct
                seller” who is allotted the “Direct seller registration ID” and
                the Compliance Officer on behalf of Tirrent GlobalNETWORK
                PRIVATE LIMITED in token of acceptance of the terms and
                conditions elaborated here above.
              </p>
              <p>ANNEXE I (Attached and Provided Separately)</p>
              <p>
                This annexe is in accordance with the Clause 1 sub clause 1.1(i)
                of Direct Seller Agreement of Tirrent Global LLC.
              </p>
              <ol type="1">
                <li>Policy and Procedure Rulebook</li>
                <li>
                  Tirrent Global Product and Specifications. 3. Direct Sellers
                  Terms & Conditions
                </li>
                <li>Policy Guidelines for Presentation</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SellerAgreement;
