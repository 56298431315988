import Aos from "aos";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";

function PoliciesProcedures() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();

    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area privacy-policy">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">POLICIES AND PROCEDURE RULEBOOK</h2>
            <div className="container">
              <div className="title-item">
                <h2>POLICIES AND PROCEDURE RULEBOOK</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>POLICIES AND PROCEDURE RULEBOOK </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <p>
                This document is also in accordance rules and guidance of Direct
                Seller Agreement of Tirrent Global LLC. executed with the Direct
                Seller.
              </p>

              <h4>Part 1: INTRODUCTION</h4>
              <ol>
                <li>
                  Tirrent Global LLC. (the ‘Company’ or ‘Tirrent Global’) is
                  dealing in research Engine oil additive product and that
                  Product available to consumer though Direct Sellers. Tirrent
                  Global LLC Company’s Policies and Procedures Rulebook is
                  designed to establish certain principles required to be
                  followed in the development and maintenance of Tirrent Global
                  Distributorship and the rights, duties, obligations and
                  responsibilities of each Tirrent Global Direct Seller.
                </li>
                <li>
                  Tirrent Global LLC honours all federal, provincial, and
                  municipal regulations governing direct selling and requires
                  every Tirrent Global Direct Seller to do the same. It is,
                  therefore, very important that you read and understand the
                  information in this Rulebook. If you have any questions
                  regarding any rule or policy, seek an answer from your
                  Sponsor, up line leader or Tirrent Global LLC. directly.
                  Review the Professional Ethics and the Policies & Procedures
                  often.
                </li>
                <li>
                  Tirrent Global and its Direct Seller have a binding
                  contractual relationship. The terms and conditions of this
                  relationship are set forth in the Tirrent Global Direct Seller
                  Application Form, Tirrent Global Policies and Procedure
                  Rulebook, Tirrent Global Compensation Plans as amended by
                  Tirrent Global LLC. from time-to-time. Tirrent Global may
                  notify all such amendments to the Direct Seller by publication
                  on its website: www.TirrentGlobal.com and other places where
                  possible.
                </li>
              </ol>
              <h4>
                Part 2: PROTOCOLS AND ETHICS OF TIRRENT GLOBAL DIRECT SELLER:
              </h4>
              <ol>
                <li>
                  As owner of my Tirrent Global distributorship, I agree to
                  conduct Tirrent Global Business according to the following
                  ethical guidelines:
                  <ol type="a">
                    <li>
                      I will be honest and fair in my dealing as a Direct
                      Seller.
                    </li>
                    <li>
                      I will follow the Policies and Procedure Rulebook and
                      Terms and Conditions of distributorship as mentioned in
                      Tirrent Global Direct Seller Application as well as all
                      applicable laws, rules and regulations observing not only
                      “the letter” but also “the sprit” thereof.
                    </li>
                    <li>
                      I will perform my Direct seller business in a manner that
                      will enhance my reputation and the positive reputation
                      established by Tirrent Global LLC.
                    </li>
                    <li>
                      I will present Tirrent Global’s Products and the Tirrent
                      Global’s Compensation Plan to all prospective Direct
                      Sellers and Preferred Customers in an accurate, fair,
                      truthful and honest manner, and I will make sure to
                      present only what is approved in official Tirrent Global’s
                      literatures.
                    </li>
                    <li>
                      I will fulfil my leadership responsibility as a Sponsor,
                      which include training, supporting and communicating with
                      the Direct Sellers in my organisation.
                    </li>
                    <li>
                      I will not engage in deceptive or illegal practices and
                      will not misrepresent Tirrent Global Products or the
                      Compensation Plan. In my Tirrent Global product sales
                      activities, and for the purposes of protecting the Tirrent
                      Global Company Plan, I will use only the Company’s
                      authorised publications.
                    </li>
                    <li>
                      I understand and agree that I am solely responsible for
                      all financial and/or legal obligations I incurred in the
                      course of my business as Tirrent Global Direct Seller and
                      will discharge all debts and duties as required of a
                      Direct Seller.
                    </li>
                  </ol>
                </li>
                <li>
                  Abide by Rules: The applicant shall confirm that he has read
                  the Policies and Procedures Rulebook and has completely
                  understood all contents before filling out his Tirrent Global
                  Direct Seller Application or Preferred Customer Application.
                  He must also abide by all the regulations and stipulations
                  governing Tirrent Global Direct Sellers/ Preferred Customers.
                </li>
                <li>
                  Effective Date and Authorisation for order: An application
                  shall be considered as accepted when the Company
                  informs/notifies the Tirrent Global Direct Seller accordingly
                  and an applicant becomes a direct seller when the Company
                  accepts such application and conveys such acceptance by
                  issuance of ID and Direct Seller identification card. The
                  Tirrent Global Direct Seller may order products through
                  Product Purchase Order Form immediately after receipt of
                  relevant notification by the Company.
                  <br />
                  <p>
                    No applicant, as a condition to becoming a new Tirrent
                    Global Direct Seller shall be required to, nor any currently
                    authorised Tirrent Global Direct Seller is required to:-
                    <ol type="a">
                      <li>
                        Purchase large quantity of inventory solely for the
                        purpose of qualifying for sales bonus or rank
                        advancement.
                      </li>
                      <li>
                        Promote inventory loading in the distribution channel.
                      </li>
                      <li>
                        Purchase tapes, literature, audio-visual aids, or other
                        materials or programs.
                      </li>
                      <li>
                        Purchase tickets for and/or attend or participate in,
                        seminars, or other meetings without his/her own concern.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  Abide by Rules: The applicant shall confirm that he has read
                  the Policies and Procedures Rulebook and has completely
                  understood all contents before filling out his Tirrent Global
                  Direct Seller Application or Preferred Customer Application.
                  He must also abide by all the regulations and stipulations
                  governing Tirrent Global Direct Sellers/ Preferred Customers.
                </li>
                <li>
                  Validity of Tirrent Global Distributorship: A distributorship
                  application once accepted will continue to remain valid as
                  long as Direct Seller continued to sell Tirrent Global
                  Products. His/her Tirrent Global distributorship will come to
                  an end if he/she fails to make any sale for a years from the
                  date of his last sales.
                </li>
              </ol>
              <h4>Part 3: THESAURUS OF TERMS</h4>
              <ol>
                <li>
                  “Company Collaterals "
                  <br />
                  Means all products and services (including but not limited to
                  business aids, books, magazines and other printed material,
                  online literature, internet websites, audio, video or digital
                  media, meetings and educational seminars (inclusive of tickets
                  for the same) and other types of materials and services) that:
                  <ol type="a">
                    <li>
                      are designed to educate prospective Preferred Customers of
                      Tirrent Global products or services, or to support, train,
                      motivate and/or educate Tirrent Global Direct Sellers;
                    </li>
                    <li>
                      incorporate or use one or more of Tirrent Global’s
                      trademarks, service marks or copyrighted works; or
                    </li>
                    <li>
                      are otherwise offered with an explicit or implied sense of
                      affiliation, connection or association with Tirrent Global
                      LLC.
                    </li>
                  </ol>
                </li>
                <li>
                  “Earnings”
                  <br />
                  means any income achieved by an Tirrent Global Direct Seller
                  in the form of commissions, fixed or variable, direct or
                  indirect, rewards, bonuses or in other forms.
                </li>
                <li>
                  “Tirrent Global Direct Seller” or a “Collaborative Entity”
                  <br />
                  mean a person (individual, firm, LLP or Pvt. Ltd. Company)
                  appointed by Tirrent Global on a protocol-to-protocol basis
                  through Tirrent Global Direct Seller Agreement to undertake
                  sale, distribution and marketing of Tirrent Global’s Products
                  and services and to register Preferred Customers.
                </li>
                <li>
                  “Tirrent Global Company Plan”
                  <br />
                  means the plan providing details of Tirrent Global’s
                  performance incentive system, sponsoring procedure and
                  guidelines, Policies and Procedures regarding selling Tirrent
                  Global’s products as amended from time to time by Tirrent
                  Global, and which forms an integral part of the Tirrent Global
                  Direct Seller Agreement.
                </li>
                <li>
                  “Preferred Customer”
                  <br />
                  means any person who is not an Tirrent Global Direct Seller
                  but an end user who’s Preferred Customer Application has been
                  accepted by Tirrent Global and who may accordingly purchase
                  Tirrent Global’s Products according to the applicable terms.
                </li>

                <li>
                  “Tirrent Global Direct Seller Agreements”
                  <br />
                  mean the agreement setting forth the terms and conditions for
                  a Direct Sellers, entered into through Tirrent Global Direct
                  Seller Application, inclusive of its terms and conditions and
                  includes following:
                  <ol type="a">
                    <li>Tirrent Global Direct Seller Application Form</li>
                    <li>
                      The terms and conditions forming part of the Tirrent
                      Global Direct Seller Application
                    </li>
                    <li>
                      The Tirrent Global Direct Seller rulebook with Policies
                      and Procedure.
                    </li>
                    <li>Tirrent Global Company Plan</li>
                  </ol>
                </li>
                <li>
                  “Products”
                  <br />
                  mean items manufactured and/or marketed and/or advertised by
                  the Company for the purpose of sale and/or commercial
                  exploitation and shall include all future items as may be
                  manufactured and/or marketed and/or advertised by the Company
                  for purposes of sale and/or commercial exploitation. It also
                  includes Literature, training and other support material, made
                  available by Tirrent Global to its Direct Sellers and/or
                  Preferred Customers.
                </li>
                <li>
                  “Product Purchase Order Form”
                  <br />
                  means the application used to order products of the Company.
                </li>
                <li>
                  “Intellectual Property”
                  <br />
                  includes existing, proposed and future Intellectual Property
                  in the nature of unregistered or registered rights to any and
                  all patents, copyrights, trademarks, trade-secrets,
                  trade-specifications, trade knowledge, technical or industrial
                  know-how and other confidential and/or proprietary information
                  and inclusive of all intellectual property that is the subject
                  of ownership by the Company and/or its subsidiaries, venture
                  partners and predecessors in interest, business and/or title,
                  and/or other business arrangements, inclusive of but not
                  limited to any oral arrangement which the Company may have
                  entered into with the Party or other party.
                </li>
              </ol>
              <h4>
                Part 4: TIRRENT GLOBAL DIRECT SELLER RESPONSIBILITIES &
                OBLIGATION
              </h4>
              <p>
                Direct Sellers are Distributors of Tirrent Global Products, they
                must thus keep themselves suitably educated about Companies
                Products, continue to meet sales criteria set by Tirrent Global
                in order to earn and retain their level as Direct Sellers.
                Tirrent Global Direct Seller must abide by following:
              </p>
              <ol>
                <li>
                  Professional Conduct:
                  <p>
                    An Tirrent Global Direct Seller shall at all times conduct
                    himself or herself in a courteous and considerate manner and
                    shall not engage in any high-pressure selling, but shall
                    make a fair presentation of Tirrent Global Products and the
                    Compensation Plan including, when and where appropriate,
                    demonstrations of such products. A Tirrent Global Direct
                    Seller shall never impose himself or herself upon his/her
                    prospective customer and shall:
                    <ol type="a">
                      <li>
                        Always take a prior permission or appointment before
                        approaching a prospective customer and indicate the
                        purpose of his or her visit and identify himself or
                        herself as a Tirrent Global Direct Seller.
                      </li>
                      <li>
                        Provide the following information to the customer at the
                        time of sale;
                        <ul>
                          <li>
                            His/her name, address and telephone number along
                            with that of Tirrent Global;
                          </li>

                          <li>
                            Carry identity card provided by Tirrent Global along
                            with any photo ID's issued by any Government agency;
                          </li>
                          <li>
                            Fix time & place for inspection of the sample and
                            delivery of goods, if customer so desires;
                          </li>
                        </ul>
                      </li>
                      <li>
                        If the customer indicates a desire to terminate the
                        interview, the Tirrent Global Direct Seller shall
                        immediately do so and shall leave the premises of the
                        customer.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  Transaction Submission Integrity:
                  <br />
                  <ol type="a">
                    <li>
                      t is essential that all relevant transactions relating to
                      and arising out of the Agreement between the Tirrent
                      Global Direct Seller/Preferred Customer and the Company be
                      submitted to the Company in order to maintain the
                      integrity of communications between the Company and the
                      Tirrent Global DirectSeller/ Preferred Customer.
                    </li>
                    <li>
                      The Tirrent Global Direct Seller should not communicate
                      any transactions submissions on behalf of another Direct
                      Seller, Tirrent Global Distributorship applicant or
                      Preferred Customer.
                    </li>
                    <li>
                      The Tirrent Global Direct Seller shall not use his or her
                      credit card or bank account on behalf of another
                      individual or Tirrent Global Direct Seller, except as
                      expressly permitted by the Company in advance for an
                      alternate payer. This rule is applicable to any and all
                      forms of transactions submissions, including, but not
                      limited to , online, telephone, fax, email, Etc.
                    </li>
                  </ol>
                </li>
                <li>
                  Business Conduct:
                  <br />
                  <ol type="a">
                    <li>
                      In the conduct of business, the Tirrent Global Direct
                      Seller shall safeguard and promote the reputation of the
                      Products and services of the Company and shall refrain
                      from all conduct which might be harmful to the reputation
                      of the Company or to the marketing of such Products and
                      services or inconsistent with the public interest, and
                      shall avoid all discourteous, deceptive, misleading,
                      unethical or immoral conduct or practices or negative
                      propaganda or adverse publicity.
                    </li>
                    <li>
                      A Tirrent Global Direct Seller shall not interfere with,
                      harass or undermine other Tirrent Global Direct Sellers
                      and, at all times, shall respect the privacy and business
                      interests of otherTirrent GlobalDirect Sellers.
                    </li>
                    <li>
                      A Tirrent Global Direct Seller must not disparage the
                      Company, other Tirrent Global Direct Sellers, Company
                      products and/or services, the marketing and compensation
                      plans, or Company employees.
                    </li>
                    <li>
                      Each Tirrent Global Direct Seller is to bear all cost of
                      building up his/her Tirrent Global business.
                    </li>
                  </ol>
                </li>
                <li>
                  Principal to Principal Contract:
                  <br />
                  <p>
                    Tirrent Global Direct Sellers are independent contractors
                    and act on principal to principal basis. They are not to be
                    considered purchasers of a franchise, nor does this create
                    an employer/employee relationship, agency, partnership, or
                    joint venture between the Company and its Direct Sellers.
                    When presenting the Tirrent Global business opportunity to
                    others, the Tirrent Global Direct Sellers must clearly state
                    the independent character of this business. The Tirrent
                    Global Direct Seller, as an independent contractor, is fully
                    responsible for all of his/her verbal and written statements
                    made regarding the products, services and/or marketing
                    programme, which are not expressly contained in writing in
                    the current Tirrent Global Direct Seller agreement,
                    advertising or promotional materials supplied by the
                    Company. The Tirrent Global Direct Seller agrees to
                    indemnify the Company and hold it harmless from any and all
                    liability including judgments, civil penalties, refunds,
                    attorney fees, court costs, lost business or claims
                    including third party claims as a result of Direct seller’s
                    unauthorised representations or arising out of Tirrent
                    Global Direct Seller’s business practices and actions.
                  </p>
                </li>
                <li>
                  Responsibility for Paying Taxes:
                  <br />
                  <ol type="a">
                    <li>
                      All Tirrent Global Direct Sellers are personally
                      responsible for compliance with all relevant taxation laws
                      and regulations of India that may arise out of his
                      earnings from distribution of the Products of the Company
                      or any other earnings generated as a seller of Company
                      products and services.
                    </li>
                    <li>
                      The Company will comply fully with any court order or
                      instruction/demand by any Indian Government taxation
                      authority, in relation to a Direct Seller’s earnings as a
                      result of his Tirrent Global Distributorship. Besides any
                      and all payments by the Company to the Tirrent Global
                      Direct Seller towards Direct Seller’s entitlements of
                      whatsoever nature shall be subject to deduction of Tax at
                      source (TDS) at applicable rates and a Direct Seller must
                      possess Income Tax Permanent Account Number (PAN) under
                      the provisions of Indian Income Tax Act 1961 and provide a
                      copy of the PAN card issued to the Direct Seller by the
                      income tax department, to the Company.
                    </li>
                  </ol>
                </li>
                <li>
                  Vendor Confidentiality:
                  <br />
                  <p>
                    The Company’s business relationship with its vendors,
                    manufacturers and suppliers is confidential. Tirrent Global
                    Direct Seller shall not contact directly or indirectly, or
                    speak to or communicate with any representative of any
                    supplier or manufacturers of the Company except at a company
                    sponsored event at which the representative is present at
                    the request of the Company.
                  </p>
                </li>
                <li>
                  Information Confidentiality:
                  <br />
                  <p>
                    On a periodic basis, the Company may supply data processing
                    information and reports to the Tirrent Global Direct Seller,
                    which will provide information concerning the Tirrent Global
                    Direct Seller’s sub-Tirrent Global Direct Seller’s sales
                    organisation, Preferred Customers, product purchases and
                    product mix.
                    <br />
                    The Tirrent Global Direct Seller agrees that:
                    <ol type="a">
                      <li>
                        Such information is proprietary and confidential to the
                        Company and is transmitted to the Tirrent Global Direct
                        Seller in confidence.
                      </li>
                      <li>
                        He will not disclose such information to any third party
                        directly or indirectly, nor use the information to
                        compete with the Company directly or indirectly during
                        or after the term of the Agreement.
                      </li>
                      <li>
                        Tirrent Global Direct Seller must take appropriate steps
                        to safeguard and protect all private information
                        including without limitation, banks account details,
                        credit card and other details provided by a retail
                        customer, prospective Tirrent Global Direct Seller or
                        other Tirrent Global Direct Seller.
                      </li>
                      <li>
                        The Tirrent Global Direct Seller and the Company agree
                        that, except as provided for by the confidentiality and
                        nondisclosure provisions of the Agreement, the Company
                        would not provide the above confidential information to
                        the Tirrent Global Direct Seller.
                      </li>
                      <li>
                        Tirrent Global Direct Seller are responsible for secured
                        handling and storage of all documents that may contain
                        private information of retail customers and other
                        Tirrent Global Direct Sellers.
                      </li>
                      <li>
                        irrent Global Direct Seller must adopt, implement and
                        maintain appropriate administrative, technical and
                        physical safeguard to protect against anticipated
                        threats or hazards to the security of confidential
                        information and customer data.
                      </li>
                      <li>
                        A Tirrent Global Direct Seller seeking to sell his
                        Tirrent Global Distributorship must acknowledge and
                        agree to this provision prior to the finalisation of the
                        sale of their Tirrent Global Distributorship.
                      </li>
                    </ol>
                  </p>
                </li>
              </ol>
              <h4>Part 5: SPONSORS’S SUPPORT/RESPONSIBILITIES</h4>
              <ol>
                <li>
                  Sponsoring Right:
                  <br />
                  <p>
                    All Tirrent Global Direct Sellers have the right to sponsor
                    others. In addition, every person has the ultimate right to
                    choose his own sponsor. If two Tirrent Global Direct Sellers
                    should claim to be the sponsors of the same new Tirrent
                    Global Direct Seller, the Company shall regard the first
                    application received by the corporate home office as
                    controlling. As a general rule, it is good practice to
                    regard the first Tirrent Global Direct Seller who has
                    meaningfully worked with a prospective Tirrent Global Direct
                    Seller as having the first claim to sponsorship though this
                    is not necessarily the sole factor of consideration. Other
                    factors of consideration such as common sense and equitable
                    principles should also be utilised.
                  </p>
                </li>
                <li>
                  Responsibility to submit completed application with documents:
                  <br />
                  <p>
                    The Company may provide the Tirrent Global Direct Sellers
                    with various methods of submitting registration or
                    information of newly sponsored Tirrent Global Direct
                    Sellers, including but not limited to, facsimile
                    registration and online registration for the convenience of
                    the Tirrent Global Direct Sellers. Till the Company receives
                    a duly completed application, containing all appropriate and
                    required information as well as the signature of the
                    proposed new Tirrent Global Direct Seller, the Company will
                    only consider any such application as one which is merely a
                    non-binding expression of interest. Although the Company is
                    attempting to create convenience for its sponsoring Tirrent
                    Global Direct Sellers, it is the responsibility of each
                    sponsoring Tirrent Global Direct Sellers to cause delivery
                    to the Company of a completed and signed Tirrent Global
                    Direct Sellers agreement if the sponsor is expected to be
                    recognised as the official sponsoring Tirrent Global Direct
                    Seller.
                  </p>
                </li>
                <li>
                  Responsibility to supervise:
                  <br />
                  <p>
                    Tirrent Global Direct Sellers must also be able to provide
                    to the Company annually, proof of ongoing fulfilment of
                    their sponsorship responsibilities. Any Tirrent Global
                    Direct Seller, who sponsors other Tirrent Global Direct
                    Sellers or sub-Tirrent Global Direct Sellers, must fulfil
                    the obligation of performing a bonafide supervisory,
                    distributing and selling function in the sale and/or
                    delivery of product to the ultimate consumer and in the
                    training of those sponsored.
                  </p>
                  <p>
                    Tirrent Global Direct Sellers must have ongoing contact,
                    commuPrincipal to Principal Contract: nication and
                    management supervision with their sales organisation(s). For
                    the avoidance of doubt, potential Tirrent Global Direct
                    Sellers shall not be required to provide any form of payment
                    or benefit in order to be sponsored by the Tirrent Global
                    Direct Seller. Examples of such supervision may include, but
                    are not limited to: written correspondence, personal
                    meetings, telephone contact, voice mail, electronic mail,
                    training sessions, accompanying individuals to company
                    training etc.
                  </p>
                </li>
                <li>
                  Responsibility to train:
                  <p>
                    Those who sponsor widely but who do not help new Tirrent
                    Global Direct Sellers develop their business will be met
                    with limited success. Therefore, it is the Tirrent Global
                    Direct Sellers’s responsibility to follow through and make
                    sure the new Tirrent Global Direct Sellers is properly
                    informed and trained in the areas of product knowledge, the
                    compensation plan, the professional guidelines of the
                    network marketing industry, the Company Policies, Rulebook
                    and Agreement. Each sponsor shall conduct, or provide access
                    to, training and motivation that complies with the Policies
                    and Procedures Rulebook.
                  </p>
                  <p>
                    In order to meet this obligation, the Sponsor shall
                    encourage his sponsored Direct Seller to attend training
                    organised by Tirrent Global or personally train the Direct
                    Sellers whom he or she sponsored or arrange for support from
                    other direct sellers, including his or her up line, Direct
                    Seller Training Providers. In all cases the Sponsor remains
                    responsible and accountable under the Tirrent Global Direct
                    Seller Contract for ensuring that compliant training and
                    motivation are made available to his or her sponsored Direct
                    Sellers.
                  </p>
                </li>
              </ol>
              <h4>
                Part 6: PROMOTION OF TIRRENT GLOBAL PRODUCTS AND OPPORTUNITIES
              </h4>
              <ol>
                <li>
                  Sales Presentation/Advertising Correct Information:
                  <ol type="a">
                    <li>
                      The Direct Seller shall be basically selling and
                      distributing the products of the Company for incentives by
                      way of Direct Seller’s margin. The Company’s products
                      purchased by Tirrent Global are for personal use of Direct
                      Seller or use by family or relatives and otherwise are not
                      for resale. All Company products are sold at
                      Company-specified prices. A Direct Seller shall not
                      advertise for sale or put up for sale Company’s products
                      that deviates from Company pricing. A Direct Seller shall
                      not advertise, offer or provide a customer with a rebate
                      or other consideration to facilitate a lower customer
                      price for Company’s products.
                    </li>
                    <li>
                      Tirrent Global Direct Seller shall not advertise Company
                      products/services/ marketing plans except as approved by
                      the Company.
                    </li>
                  </ol>
                </li>
                <li>
                  Retail Establishment (Pickup Centres):
                  <ol type="a">
                    <li>
                      Company products or services may only be displayed, sold
                      and delivered in retail establishments where the nature of
                      the business is to make appointments with customers (such
                      as Mechanics, Service offices, and Filling stations where
                      appointments are scheduled).
                    </li>
                    <li>
                      The sale and delivery of such products or services within
                      such retail facilities must be conducted by a Direct
                      Seller and must be preceded by a discussion where the
                      Direct Seller introduces the Company’s products or
                      services and opportunities just as they would if they had
                      met outside of the retail facility. Company produced
                      literature, banners, or signage only shall be displayed on
                      a shelf, counter, or wall and must be displayed by itself.
                    </li>
                    <li>
                      Company products or services shall not be sold in any
                      retail establishment, even by appointment, if competitive
                      products or services are sold in that establishment.
                    </li>
                    <li>
                      From time to time, the Company may announce policies and
                      rules that expand or contract restrictions on sales in
                      retail establishments.
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>Part 7: BUSINESS COLLATERALS</h4>
              <p>
                The content, review and authorisation, creation, production,
                promotion, distribution, offering for sale and sale of Business
                Collateral Support Materials shall strictly adhere to this
                Section 7 and other applicable provisions in this Policies.
              </p>
              <ol>
                <li>General Rule for Business Collaterals:</li>
                <li>
                  Tirrent Global recognises the importance of training to
                  Tirrent Global Direct Sellers and produces Business
                  Collaterals for representation of its business opportunity and
                  explaining about the Products. Only such Company produced
                  Business Collaterals are to be used by Tirrent Global Direct
                  Sellers in promotion of their Tirrent Global business.
                </li>
                <li>
                  No Third Party produced Business Collateral whether developed
                  by Tirrent Global Direct Seller or any independent person can
                  be used for promotion of business or product of Tirrent
                  Global, except with explicit written consent from Tirrent
                  Global. Tirrent Global reserve its right to grant limited
                  license to Senior Tirrent Global Direct Seller to produce
                  their own Business Collaterals, provided such collaterals
                  complies with requirement of these policies and are submitted
                  for review and approval to Tirrent Global before their
                  production or dissemination.
                </li>
                <li>
                  Such Approved Third-Party Business Collaterals shall not be
                  offered for sale or sold to prospective Tirrent Global Direct
                  Seller or Preferred Customers or Tirrent Global Direct seller.
                  Tirrent Global Direct Seller may neither give to, nor receive
                  compensation, remuneration or other financial incentives from,
                  other Tirrent Global Direct Seller for the promotion,
                  distribution, offering for sale of such Approved Third-Party
                  Business Collaterals and they shall only be used to complement
                  the Company produced Business Collaterals.
                </li>
                <li>
                  Such Approved Third-Party Business Collaterals shall not
                  infringe in any way on Tirrent Global copyrights, trademarks
                  or other intellectual property rights, or provisions of any
                  other applicable laws, rules or regulations.
                </li>
                <li>
                  Such Business Collaterals shall not suggest, imply, promise or
                  guarantee, either directly or indirectly, that any specific
                  level or amount of sales, income, profit or earnings may be
                  derived from the Tirrent GlobalBusiness or from selling any
                  specific line or lines of Tirrent Global Product.
                </li>
                <li>
                  Any claim relating to the use, characteristics and/or
                  performance of Tirrent Global Products must be reproduced
                  verbatim from official Tirrent Global sources, without
                  alteration or modification. No third- party claim for the same
                  shall be authorised in any such Business Collateral. Tirrent
                  Global Direct Sellers may, with prior company’s approval
                  given, incorporate into their communications or include in
                  presentations reduced to a fixed medium full and exact
                  reproduction(s) of materials set forth in Tirrent Global
                  produced literature, Tirrent Global website, or Tirrent Global
                  produced labels.
                </li>
              </ol>
              <h4>Part 8: BUSINESS PRACTICES</h4>
              <ol>
                <li>
                  Being Truthful and Accurate: No Direct Seller shall:
                  <ol type="a">
                    <li>
                      Make any offer to sell any Tirrent Global product which is
                      not accurate and truthful as to price, grade, quality,
                      performance, and availability.
                    </li>
                    <li>
                      Make statements or representations regarding the Company
                      or its Products other than those approved and provided in
                      writing by the Company such as its Company Policies and
                      Procedures Rulebook and/or any other official Company
                      printed literature and publications.
                    </li>
                    <li>
                      In any way whatsoever, represent incorrectly with regard
                      to prices, quality, standards, grades, contents, style or
                      model, place of origin or availability of Tirrent Global
                      Products.
                    </li>
                  </ol>
                </li>
                <li>
                  Product Claims: No Direct Seller shall:
                  <ol type="a">
                    <li>
                      Make any claim that the Company products are intended to
                      Repair, Replace. Such statements can be perceived as
                      miracle claims and unsubstantiated claims such as these
                      are strictly against general Company policies and shall
                      also violate relevant laws in India. Pursuant to the
                      Company’s general policies of prohibition of replacement
                      or treatment claims, the Company enumerates the following
                      specific policies which include but are not be limited to:
                      <ul>
                        <li>
                          Tirrent Global Direct Sellers are prohibited from
                          making miracle, curative or treatment claims, whether
                          expressed or implied; ii.Tirrent Global Direct Seller
                          are prohibited from using in any and all of their
                          marketing materials and promotion any descriptions
                          that are regarded as Repair and Replacement claims
                          stating that; Products of the Company under the brand
                          name “Tirrent Global” may fill, treat, repair.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Make exaggerated product claims or non-guaranteed claims
                      with regard to Tirrent Global Products.
                    </li>
                    <li>
                      Agree to make no false or fraudulent representations about
                      the Company, the products, the Company compensation plan,
                      or income potentials.
                    </li>
                  </ol>
                </li>
                <li>
                  Income Claims:
                  <p>
                    No income claims, income projections, income representation,
                    or showing of Direct Seller Margin checks (“check waving”)
                    shall be made to prospective Direct Sellers. Any false,
                    deceptive or misleading claims regarding the opportunity or
                    product/service are prohibited. In their enthusiasm, Tirrent
                    Global Direct Sellers are occasionally tempted to represent
                    hypothetical income figures based upon the inherent power of
                    network marketing as actual income projections.
                  </p>
                  <p>
                    This is counterproductive, since new Direct Sellers may be
                    quickly disappointed if their results are not as extensive
                    or as rapid as a hypothetical model would suggest. The
                    Company believes firmly that the income potential is good
                    enough to be reasonably attractive in reality without
                    resorting to artificial and unrealistic projections.
                  </p>
                </li>
                <li>
                  Prohibition on Raiding and Cross-Solicitation of Products or
                  Other Business Opportunities:
                  <ol type="a">
                    <li>
                      The Company takes seriously its responsibility to protect
                      the livelihood of its sales forces and hard work invested
                      to build a sales organisation. Raiding and solicitation
                      actions in which Tirrent Global Direct Sellers seek to
                      raid and solicit other Tirrent Global Direct Sellers in
                      the sales organisation to non- company products and
                      services and to other business opportunities, severely
                      undermines the marketing programme of the Company,
                      interferes with the relationship between the Company and
                      its sales force and destroys the livelihood of other
                      Tirrent Global Direct Sellers who have worked hard to
                      build their own business, the business of their sales and
                      benefits they have earned by helping to build a sales
                      organisation. Therefore, Tirrent Global Direct Sellers
                      shall not directly or indirectly sell to, nor solicit
                      from, other Company Direct Sellers, non-company products
                      or services, or in any way promote to other Company Direct
                      Sellers business opportunities in marketing programme of
                      other business opportunity companies at any time.
                    </li>
                    <li>
                      Tirrent Global Direct Sellers shall not engage in any
                      recruiting or promotion activity that targets Company
                      Direct Sellers for opportunities or products of other
                      direct selling companies or business opportunities, either
                      directly or indirectly, by themselves or in conjunction
                      with others, nor shall a Tirrent Global Direct Seller
                      participate, directly or indirectly, in interference,
                      raiding or solicitation activity of Company Direct Seller
                      for other direct selling companies or business
                      opportunities. Unless approved in writing by the Company,
                      this general prohibition includes sales or solicitation of
                      non-company products or services at meetings organised for
                      Company sales, promotion, training recruitment,
                      demonstration, etc. This prohibition on targeting,
                      interference, soliciting and raiding shall be in effect
                      during the term of the Tirrent Global Direct Seller
                      Agreement and for a period of three (3) years after the
                      termination of the Tirrent Global Direct Seller Agreement.
                      For the term of this agreement and for three (3) years
                      after termination hereof, a Direct Seller shall not,
                      directly or indirectly, recruit any of Company’s Tirrent
                      Global Direct Sellers to join other direct sales or
                      network marketing companies nor solicit, directly or
                      indirectly, Company’s Tirrent Global Direct Sellers to
                      purchase services or products, or in any other way
                      interfere with the contractual relationships between
                      Company and its Tirrent Global Direct Sellers.
                    </li>
                  </ol>
                </li>
                <li>
                  Repackaging:
                  <p>
                    Repackage, or otherwise change or alter any of packaging
                    labels of Tirrent Global product is prohibited.
                  </p>
                </li>
              </ol>
              <h4>
                Part 9: USE OF TIRRENT GLOBAL’S TRADEMARK, TRADE NAME AND
                COPYRIGHT MATERIAL
              </h4>
              <p>
                The name of the Company and other names as may be adopted by the
                Company are proprietary trade names and trademarks of the
                Company. As such, these marks are of great value to the Company
                and are supplied on permissive, nonexclusive and terminable
                license basis to Tirrent Global Direct Sellers for use only in
                an expressly authorised manner exclusively in relation to the
                distribution of the Products of the Company.
              </p>
              <p>Following are the terms of using:</p>
              <ol>
                <li>
                  Tirrent Global Direct Sellers agree not to advertise Company
                  products or services in any way other than the advertising or
                  promotional materials made available to Tirrent Global Direct
                  Sellers by the Company.
                </li>
                <li>
                  Tirrent Global Direct Sellers agree not to use any written,
                  printed, recorded or any other material in advertising,
                  promoting or describing the products or services or the
                  Company marketing program, or in any other manner, any
                  material which has not been copyrighted and supplied by the
                  Company, unless such material has been submitted to the
                  Company and approved in writing by the Company before being
                  disseminated, published or displayed.
                </li>
                <li>
                  The Company will not permit the use of its copyrights,
                  designs, logos, trade names, trademarks, etc., without its
                  prior written permission. Tirrent Global Direct Sellers shall
                  not use the Company logo in marketing or sales materials,
                  except upon approved business cards. The Trade Mark of the
                  Products must also be appropriately annotated.
                  <br />
                  In addition to general prohibitions on use of the Company
                  trademarks or logos, the Company specifically prohibits the
                  use of Company trademarks or logos in conjunction with the
                  sale of any other non-Company products.
                </li>
                <li>
                  All Company materials, whether printed, on film, produced by
                  sound recording, or on the internet, are copyrighted and shall
                  not be reproduced in whole or in part by Tirrent Global Direct
                  Sellers or any other person except as authorised by the
                  Company. Permission to reproduce any materials will be
                  considered only in extreme circumstances. Therefore, an
                  Tirrent Global Direct Seller should not anticipate that
                  approval will be granted, even if the same is sought by the
                  Tirrent Global Direct Seller in this regard.
                </li>
                <li>
                  A Tirrent Global Direct Seller shall not produce, use or
                  distribute any information relative to the contents,
                  characteristics or properties of Company product or service
                  which has not been provided directly by the Company. This
                  prohibition includes but is not limited to, print, audio or
                  video media.
                </li>
                <li>
                  A Tirrent Global Direct Seller shall not produce, sell or
                  distribute literature, films or sound recordings which are
                  deceptively similar in nature to those produced, published and
                  provided by the Company for its Tirrent Global Direct Sellers.
                  Nor shall an Tirrent Global Direct Seller purchase, sell or
                  distribute non-company materials which imply or suggest that
                  said materials originate from the Company or are in any manner
                  similar to the Products of the Company in functionality,
                  purpose, utility or otherwise.
                </li>
                <li>
                  Any display ads or institutional or trademark advertising
                  copy, other than covered in the foregoing rules, must be
                  submitted to the Company and be approved in writing by the
                  Company prior to its publication.
                </li>
                <li>
                  All advertising, direct mailing, and display must be approved
                  in writing before being disseminated, published or displayed.
                  Advertising on radio, TV, newspapers, magazines, and websites
                  except through the permitted website under Clause 11.2 is
                  strictly prohibited.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PoliciesProcedures;
