import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area privacy-policy">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">Privacy Policy</h2>
            <div className="container">
              <div className="title-item">
                <h2>Privacy Policy</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <p>
                Thank you for visiting Tirrent Global LLC. This privacy policy
                tells you how we use personal information collected at this
                site. Please read this privacy policy before using the site or
                submitting any personal information. By using the site, you are
                accepting the practices described in this privacy policy. These
                practices may be changed, but any changes will be posted and
                changes will only apply to activities and information on a going
                forward, not retroactive basis. You are encouraged to review the
                privacy policy whenever you visit the site to make sure that you
                understand how any personal information you provide will be
                used.
              </p>
              <p>
                <b>Note:</b> The privacy practices set forth in this privacy
                policy are for <span> tirrentglobal.com </span> only. If you
                link to other web sites, please review the privacy policies
                posted at those sites.
              </p>
              <h4>Collection of Information -</h4>
              <p>
                We collect personally identifiable information, like names,
                postal addresses, email addresses, etc., when voluntarily
                submitted by our visitors. The information you provide is used
                to fulfill you specific request. This information is only used
                to fulfill your specific request, unless you give us permission
                to use it in another manner, for example to add you to one of
                our mailing lists.
              </p>
              <h4>Distribution of Information -</h4>
              <p>
                We may share information with governmental agencies or other
                companies assisting us in fraud prevention or investigation. We
                may do so when:{" "}
              </p>
              <ol>
                <li>Permitted or required by law </li>
                <li>
                  Trying to protect against or prevent actual or potential fraud
                  or unauthorized transactions;{" "}
                </li>
                <li>
                  Investigating fraud which has already taken place. The
                  information is not provided to these companies for marketing
                  purposes.
                </li>
              </ol>
              <h4>Commitment to Data Security</h4>
              <p>
                Your personally identifiable information is kept secure. Only
                authorized employees, agents and contractors (who have agreed to
                keep information secure and confidential) have access to this
                information. All emails and newsletters from this site allow you
                to opt out of further mailings.
              </p>
              <h4>Cookie/Tracking Technology -</h4>
              <p>
                The Site may use cookie and tracking technology depending on the
                features offered. Cookie and tracking technology are useful for
                gathering information such as browser type and operating system,
                tracking the number of visitors to the Site, and understanding
                how visitors use the Site. Cookies can also help customize the
                Site for visitors. Personal information cannot be collected via
                cookies and other tracking technology, however, if you
                previously provided personally identifiable information, cookies
                may be tied to such information. Aggregate cookie and tracking
                information may be shared with third parties.
              </p>
              <h4>Privacy Contact Information –</h4>
              <p>
                If you have any questions, concerns, or comments about our
                privacy policy you may contact us using the information below:
              </p>
              <h4>By Email: support@tirrentglobal.com</h4>
              <p>
                Tirrent Global reserve the right to make changes to this policy.
                Any changes to this policy will be posted.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
