import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function SocialRes() {
  const { t } = useTranslation(["SocialRes"]);

  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2> {t("social.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-item">
                <div className="service-details-robot">
                  <h1 className="social-headings">
                    {t("social.heading1.title")}
                  </h1>
                  <p>{t("social.heading1.line1")}</p>
                  <p>{t("social.heading1.line2")}</p>
                  <h3 className="social-headings">
                    {t("social.heading2.title")}
                  </h3>
                  <ul className="mt-3">
                    <li className="contri-points">
                      {t("social.heading2.head1")}:
                    </li>
                    <p>{t("social.heading2.line1")}</p>
                    <li className="contri-points">
                      {t("social.heading2.head2")}:
                    </li>
                    <p>{t("social.heading2.line2")}</p>
                    <li className="contri-points">
                      {t("social.heading2.head3")}:
                    </li>
                    <p>{t("social.heading2.line3")}</p>
                    <li className="contri-points">
                      {t("social.heading2.head4")}:
                    </li>
                    <p>{t("social.heading2.line4")}</p>
                    <li className="contri-points">
                      {" "}
                      {t("social.heading2.head5")}:
                    </li>
                    <p>{t("social.heading2.line5")}</p>
                    <p>{t("social.heading2.line5.1")}</p>
                  </ul>

                  <h1 className="social-links-point-heading">
                    {t("social.heading3.title")}
                  </h1>
                  <div className="row mt-5 mb-5 d-flex justify-content-center">
                    <div
                      className="col-md-6 mt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/policy.png"
                        width="100%"
                        alt="Service Details"
                      />
                    </div>
                    <div className="col-md-6 mt-4">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/policy1.png"
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="social-res-box">
                    <i
                      className="fa-solid fa-comment"
                      style={{ fontSize: "40px", color: "--var-main-color" }}
                    ></i>
                    <p style={{ marginLeft: "20px", marginTop: "20px" }}>
                      {t("social.heading3.footprint")}
                    </p>
                  </div>
                  <p>{t("social.heading3.line1")}</p>
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="robot-left">
                        <ul>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l1")}
                          </li>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l2")}
                          </li>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l3")}
                          </li>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l4")}
                          </li>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l5")}
                          </li>
                          <li>
                            <i
                              className="fa fa-check"
                              style={{ fontSize: "10px" }}
                            ></i>{" "}
                            {t("social.heading3.l6")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SocialRes;
