import React from "react";
import { Link } from "react-router-dom";
import "./ErrorPage.css";
import { useTranslation } from "react-i18next";
function ErrorPage() {
  const { t } = useTranslation(["ErrorPage"]);

  return (
    <>
      <section className="error-area">
        <div className="error-item">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="error-text">
                <h1>404!</h1>
                <p>
                {t("error.title")}
                  <i className="bx bxs-sad bx-flip-horizontal bx-tada"></i>
                </p>
                <span>
                {t("error.span")}
                </span>
                <Link to="/">{t("error.return")}</Link>
                {/* <a href="index.html"></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ErrorPage;
